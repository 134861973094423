import React, { useEffect } from "react";
import { View, Platform } from "react-native";
import { Text } from "react-native-elements";
import * as Yup from "yup";
import tw from "tailwind-rn";
import moment from "moment";
import {
  Form,
  FormFieldDatePicker,
  FormFieldPicker,
} from "components/elements/forms";
import styles from "./styles";
import I18NContext from "library/contexts/i18N";

const defaultStart = moment().subtract(1, "week").format("YYYY-MM-DD");
const defaultEnd = moment().add(2, "week").format("YYYY-MM-DD");

const DateRangeFilter = ({
  onSelect,
  title,
  value = {},
  range = 30,
  showDateType = true,
  maxDate = null,
}) => {
  const { messages, Localise } = React.useContext(I18NContext);

  const dateTypesList = [
    { label: "Delivery Date", value: "DELIVERY_DATE" },
    { label: "Order Date", value: "ORDER_DATE" },
  ];
  const validationSchema = Yup.object().shape({
    startDate: Yup.date().label("startDate").required("Enter Start Date"),
    endDate: Yup.date().label("endDate").required("Enter End Date"),
  });

  const { startDate, endDate, dateType } = value;
  return (
    <View style={[styles.recordContainer]}>
      <View style={[styles.record]}>
        <View>
          <View style={tw("py-2")}>
            <Form
              initialValues={{
                startDate: startDate || defaultStart,
                endDate: endDate || defaultEnd,
                dateType: dateType || "DELIVERY_DATE",
              }}
              validationSchema={validationSchema}
              validateOnChange={false}
              validateOnBlur={false}
              enableReinitialize={true}
              render={({ values, touched, setFieldValue, isSubmitting }) => {
                let { startDate, endDate, dateType } = values;
                const minDate = moment().subtract(90, "days");

                // eslint-disable-next-line react-hooks/rules-of-hooks
                useEffect(() => {
                  if (!isSubmitting) {
                    const endDateVal = moment(startDate)
                      .add(range, "days")
                      .format("YYYY-MM-DD");

                    const isOutOfRange =
                      moment(endDate).isAfter(endDateVal) ||
                      moment(endDate).isBefore(startDate);
                    isOutOfRange && setFieldValue("endDate", endDateVal);
                    onSelect("Date Range", title, {
                      startDate,
                      endDate: isOutOfRange ? endDateVal : endDate,
                      dateType,
                    });
                  }
                }, [startDate]);

                // eslint-disable-next-line react-hooks/rules-of-hooks
                useEffect(() => {
                  if (!isSubmitting) {
                    const startRangeDate = moment(endDate).subtract(
                      range,
                      "days"
                    );
                    const startDateVal = (
                      startRangeDate.isBefore(minDate)
                        ? minDate
                        : startRangeDate
                    ).format("YYYY-MM-DD");
                    const isOutOfRange =
                      moment(startDate).isBefore(startDateVal) ||
                      moment(startDate).isAfter(endDate);

                    isOutOfRange && setFieldValue("startDate", startDateVal);
                    onSelect("Date Range", title, {
                      startDate: isOutOfRange ? startDateVal : startDate,
                      endDate,
                      dateType,
                    });
                  }
                }, [endDate]);

                return (
                  <View>
                    {showDateType ? (
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          zIndex: 20,
                          marginBottom: 10,
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            marginBottom: 10,
                          }}
                        >
                          <Text>{Localise(messages, "Date Type")} </Text>
                        </View>
                        <View
                          style={{
                            flex: 1,
                            flexDirection: "row",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <FormFieldPicker
                            placeholder={{
                              label: Localise(messages, "Select a date type"),
                            }}
                            containerStyle={{
                              zIndex: 30,
                              flex: 1,
                              marginTop: 0,
                              width: "100%",
                              height: Platform.OS !== "web" ? 40 : "auto",
                            }}
                            data={dateTypesList}
                            name="dateType"
                            label=""
                            onChange={(val) => {
                              onSelect("Date Range", title, {
                                startDate,
                                endDate,
                                dateType: val,
                              });
                            }}
                          />
                        </View>
                      </View>
                    ) : null}

                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        zIndex: Platform.OS === "web" ? 10 : 20,
                        marginBottom: 10,
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          marginBottom: 10,
                        }}
                      >
                        <Text>{Localise(messages, "Start Date")}</Text>
                      </View>
                      <View
                        style={{
                          flex: 1,
                          flexDirection: "row",
                          alignItems: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        <FormFieldDatePicker
                          dateFormat={
                            Platform.OS === "web" ? "MM/dd/yyyy" : "MM/DD/YYYY"
                          }
                          dateValueFormat="YYYY-MM-DD"
                          name="startDate"
                          placeholder={Localise(messages, "Start Date")}
                          containerStyle={{
                            zIndex: 30,
                            height: Platform.OS !== "web" ? 40 : "auto",
                            marginTop: 0,
                            flex: 1,
                          }}
                          alignIconRight={true}
                          iconName="calendar"
                          iconType="font-awesome"
                          minDate={minDate.toDate()}
                          overrideValue={startDate}
                          popperPlacement="bottom-end"
                        />
                      </View>
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        zIndex: Platform.OS === "web" ? 0 : 20,
                        marginBottom: 10,
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          marginBottom: 10,
                        }}
                      >
                        <Text>{Localise(messages, "End Date")}</Text>
                      </View>
                      <View
                        style={{
                          flex: 1,
                          flexDirection: "row",
                          alignItems: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        <FormFieldDatePicker
                          dateFormat={
                            Platform.OS === "web" ? "MM/dd/yyyy" : "MM/DD/YYYY"
                          }
                          dateValueFormat="YYYY-MM-DD"
                          name="endDate"
                          placeholder={Localise(messages, "End Date")}
                          containerStyle={{
                            zIndex: 30,
                            height: Platform.OS !== "web" ? 40 : "auto",
                            marginTop: 0,
                            flex: 1,
                          }}
                          alignIconRight={true}
                          iconName="calendar"
                          iconType="font-awesome"
                          minDate={minDate.toDate()}
                          maxDate={maxDate}
                          overrideValue={endDate}
                          popperPlacement="bottom-end"
                        />
                      </View>
                    </View>
                  </View>
                );
              }}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

export default DateRangeFilter;
