/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import React, { useMemo, useEffect, useRef, useState } from "react";

import { AgGridReact } from "@ag-grid-community/react";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { LicenseManager } from "@ag-grid-enterprise/core";
import { RichSelectModule } from "@ag-grid-enterprise/rich-select";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { SideBarModule } from "@ag-grid-enterprise/side-bar";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { ServerSideRowModelModule } from "@ag-grid-enterprise/server-side-row-model";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { MenuModule } from "@ag-grid-enterprise/menu";

import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-balham.css";
import "./global.css";

// AG-Grid Enterprise
LicenseManager.setLicenseKey(
  "Using_this_{AG_Grid}_Enterprise_key_{AG-066781}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{FTD_LLC}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Mercury_HQ}_only_for_{5}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Mercury_HQ}_need_to_be_licensed___{Mercury_HQ}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{10_September_2025}____[v3]_[01]_MTc1NzQ1ODgwMDAwMA==71f6b534aad1d918a391b0ff09ebf36e"
);
ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  RichSelectModule,
  RowGroupingModule,
  SideBarModule,
  ColumnsToolPanelModule,
  FiltersToolPanelModule,
  SetFilterModule,
  ServerSideRowModelModule,
  MenuModule,
]);

const AGGridContainer = ({
  theme = "ag-theme-balham",
  onGridReady,
  parentContainerStyle = {},
  pagination = true,
  headerHeight = 50,
  rowHeight = 100,
  paginationPageSize = 10,
  paginationPageSizeSelector = [10, 20, 30],
  suppressRowClickSelection = true,
  rowSelection = "multiple",
  rowModelType = "serverSide",
  cacheBlockSize = 10,
  columnDefs = [],
  gridOptions = {},
  ...otherProps
}) => {
  const gridRef = useRef(null);
  const [hidePanelByDefault, setHidePanelByDefault] = useState(true);

  const toggleColumnPanel = () => {
    const isPanelVisible = gridRef?.current?.api?.isSideBarVisible() ?? false;
    gridRef?.current?.api?.setSideBarVisible(!isPanelVisible);
    !isPanelVisible
      ? gridRef?.current?.api?.openToolPanel("columns")
      : gridRef?.current?.api?.closeToolPanel();
    setHidePanelByDefault(isPanelVisible);
  };

  const togglePaginationControls = (enable = true) => {
    document.querySelector(`[ref=\'btPrevious\']`).style.pointerEvents = enable
      ? "auto"
      : "none";
    document.querySelector(`[ref=\'btNext\']`).style.pointerEvents = enable
      ? "auto"
      : "none";
  };

  // Fix:- Ag-Grid showing (?) in pagination
  const setLastRowOnPage = (endRow = 0) => {
    const lbLastRowOnPageEl = document.querySelector(
      `[ref=\'lbLastRowOnPage\']`
    );
    if (!lbLastRowOnPageEl) return;
    lbLastRowOnPageEl.innerHTML = endRow.toString();
  };

  useEffect(() => {
    onGridReady &&
      onGridReady(gridRef.current, {
        toggleColumnPanel,
        togglePaginationControls,
        setLastRowOnPage,
      });
  }, [onGridReady]);

  return useMemo(() => {
    return (
      <div className={theme} style={parentContainerStyle}>
        <AgGridReact
          ref={gridRef}
          pagination={pagination}
          headerHeight={headerHeight}
          rowHeight={rowHeight}
          rowModelType={rowModelType}
          cacheBlockSize={cacheBlockSize}
          paginationPageSize={paginationPageSize}
          paginationPageSizeSelector={paginationPageSizeSelector}
          gridOptions={{
            suppressMenuHide: true,
            skipHeaderOnAutoSize: true,
            suppressHorizontalScroll: true,
            colResizeDefault: "shift",
            rowModelType: "serverSide",
            getRowId: (params) => {
              return `${params.data.id}${Math.random() * 10}`;
            },
            ...gridOptions,
          }}
          suppressRowClickSelection={suppressRowClickSelection}
          rowSelection={rowSelection}
          columnDefs={columnDefs}
          sideBar={{
            hiddenByDefault: hidePanelByDefault,
            defaultToolPanel: "columns",
            toolPanels: [
              {
                id: "columns",
                labelDefault: "Columns",
                labelKey: "columns",
                iconKey: "columns",
                toolPanel: "agColumnsToolPanel",
                toolPanelParams: {
                  suppressRowGroups: true,
                  suppressValues: true,
                  suppressPivots: true,
                  suppressPivotMode: true,
                  suppressColumnFilter: true,
                  suppressColumnSelectAll: true,
                  suppressColumnExpandAll: true,
                },
              },
            ],
          }}
          {...otherProps}
        />
      </div>
    );
  }, [parentContainerStyle, columnDefs, gridOptions]);
};

export default AGGridContainer;
