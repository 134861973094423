/* eslint-disable react/display-name */
import { setPageActions } from "library/sagas/views/home/drawer/orders-new/slice";

import {
  CustomHeaderComponent,
  StatusCellRenderer,
  RecipientCellRenderer,
  CustomerCellRenderer,
  DateCellRenderer,
  PriceCellRenderer,
  ProductCellRenderer,
  CustomFilterComponent,
  ActionsCellRenderer,
  DesignerCellRenderer,
} from "./cells";

export const orderGroupsConfig = [
  {
    category: "all",
    tabs: [{ label: "All", value: "all" }],
  },
  {
    category: "actions",
    tabs: [{ label: "Needs Action", value: true, badge: 4 }],
  },
  {
    category: "date",
    tabs: [
      { label: "Today", value: 0 },
      { label: "Tomorrow", value: 1 },
      { label: "Future", value: 2 },
    ],
  },
  {
    category: "delivery_status",
    tabs: [
      { label: "Undelivered", value: "undelivered" },
      { label: "Outgoing", value: "outgoing" },
      { label: "Delivered", value: "delivered" },
    ],
  },
  {
    category: "order_status",
    tabs: [
      { label: "Cancelled", value: "cancelled" },
      { label: "Rejected", value: "rejected" },
      { label: "Forfeited", value: "forfeited" },
    ],
  },
];

export const columnIDs = {
  status: "fulfillment",
  order_id: "correlation_id",
  delivery_date: "delivery_date",
  customer: "customer",
  recipient: "recipient",
  properties: "properties",
  price: "price",
  actions: "actions",
  designer: "designer",
  sentiment: "sentiment",
};

export const columnParams = {
  resizable: true,
  editable: false,
  hide: false,
  suppressMenu: false,
  appliedSearch: "",
  appliedFilter: {},
  appliedSort: "",
};

export const columnSortOptions = {
  fulfillment_status_asc: `${columnIDs.status}::fulfillment_status::ASC`,
  fulfillment_status_dsc: `${columnIDs.status}::fulfillment_status::DESC`,
  needs_action_status_asc: `${columnIDs.status}::needs_action_status::ASC`,
  needs_action_status_dsc: `${columnIDs.status}::needs_action_status::DESC`,
  mhq_number_asc: `${columnIDs.order_id}::mhq_number::ASC`,
  mhq_number_dsc: `${columnIDs.order_id}::mhq_number::DESC`,
  mol_number_asc: `${columnIDs.order_id}::mol_number::ASC`,
  mol_number_dsc: `${columnIDs.order_id}::mol_number::DESC`,
  wire_number_asc: `${columnIDs.order_id}::wire_number::ASC`,
  wire_number_dsc: `${columnIDs.order_id}::wire_number::DESC`,
  delivery_date_asc: `${columnIDs.delivery_date}::delivery_date::ASC`,
  delivery_date_dsc: `${columnIDs.delivery_date}::delivery_date::DESC`,
  timed_delivery_asc: `${columnIDs.delivery_date}::timed_delivery::ASC`,
  timed_delivery_dsc: `${columnIDs.delivery_date}::timed_delivery::DESC`,
  customer_first_name_asc: `${columnIDs.customer}::first_name::ASC`,
  customer_first_name_dsc: `${columnIDs.customer}::first_name::DESC`,
  customer_last_name_asc: `${columnIDs.customer}::last_name::ASC`,
  customer_last_name_dsc: `${columnIDs.customer}::last_name::DESC`,
  customer_email_name_asc: `${columnIDs.customer}::email_name::ASC`,
  customer_email_name_dsc: `${columnIDs.customer}::email_name::DESC`,
  recipient_first_name_asc: `${columnIDs.recipient}::first_name::ASC`,
  recipient_first_name_dsc: `${columnIDs.recipient}::first_name::DESC`,
  recipient_last_name_asc: `${columnIDs.recipient}::last_name::ASC`,
  recipient_last_name_dsc: `${columnIDs.recipient}::last_name::DESC`,
  recipient_address_asc: `${columnIDs.recipient}::address::ASC`,
  recipient_address_dsc: `${columnIDs.recipient}::address::DESC`,
  recipient_location_name_asc: `${columnIDs.recipient}::location_name::ASC`,
  recipient_location_name_dsc: `${columnIDs.recipient}::location_name::DESC`,
};

const getSortStatus = (sort = "", gridState) => {
  const [colId = "", rowId = "", label = ""] = sort.split("::");
  const filterState =
    gridState?.find((e) => e?.colId === colId)?.appliedSort || "";
  return filterState === `${colId}::${rowId}::${label}`;
};

export const columnDefs = (dispatch, gridState, refreshGrid, showDesigners) => {
  return {
    [columnIDs.status]: {
      ...columnParams,
      colId: columnIDs.status,
      headerName: "Status",
      menuTabs: ["filterMenuTab", "generalMenuTab"],
      width: 190,
      headerComponent: CustomHeaderComponent,
      cellRenderer: StatusCellRenderer,
      filter: CustomFilterComponent,
      filterParams: {
        filterOptions: [
          {
            rowId: "fulfillment_status",
            heading: "Order Status",
            filters: [
              {
                label: "Incoming",
                name: "Incoming",
              },
              {
                label: "Accepted",
                name: "Accepted",
              },
              {
                label: "In Design",
                name: "In Design",
              },
              {
                label: "Designed",
                name: "Designed",
              },
              {
                label: "Out for Delivery",
                name: "Out for Delivery",
              },
              {
                label: "Delivered",
                name: "Completed",
              },
            ],
          },
          {
            rowId: "needs_action_status",
            heading: "Needs Action",
            filters: [
              {
                label: "Approve Requests",
                name: "Approve Requests",
              },
              {
                label: "View Responses",
                name: "View Responses",
              },
              {
                label: "View Chats",
                name: "View Chats",
              },
            ],
          },
        ],
        sortOptions: [
          {
            label: columnSortOptions.fulfillment_status_asc,
            name: "Order Status - Ascending",
            checked: getSortStatus(
              columnSortOptions.fulfillment_status_asc,
              gridState
            ),
            action: () =>
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.fulfillment_status_asc,
                  callback: refreshGrid,
                })
              ),
          },
          {
            label: columnSortOptions.fulfillment_status_dsc,
            name: "Order Status - Descending",
            checked: getSortStatus(
              columnSortOptions.fulfillment_status_dsc,
              gridState
            ),
            action: () =>
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.fulfillment_status_dsc,
                  callback: refreshGrid,
                })
              ),
          },
          {
            label: columnSortOptions.needs_action_status_asc,
            name: "Needs Action - Ascending",
            checked: getSortStatus(
              columnSortOptions.needs_action_status_asc,
              gridState
            ),
            action: () =>
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.needs_action_status_asc,
                  callback: refreshGrid,
                })
              ),
          },
          {
            label: columnSortOptions.needs_action_status_dsc,
            name: "Needs Action - Descending",
            checked: getSortStatus(
              columnSortOptions.needs_action_status_dsc,
              gridState
            ),
            action: () =>
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.needs_action_status_dsc,
                  callback: refreshGrid,
                })
              ),
          },
        ],
      },
      cellEditorParams: {
        cellRenderer: StatusCellRenderer,
        highlightMatch: true,
        cellHeight: 40,
        values: [
          {
            label: "accepted",
            value: "Open",
            backgroundColor: "",
            color: "",
          },
          {
            label: "accepted_new",
            value: "Accepted",
            backgroundColor: "#AADEC640",
            color: "#31AA92",
          },
          {
            label: "design_new",
            value: "In Design",
            backgroundColor: "#D1E9E4",
            color: "#31AA92",
          },
          {
            label: "designed_new",
            value: "Designed",
            backgroundColor: "#357975",
            color: "white",
          },
          {
            label: "delivery_new",
            value: "Out for Delivery",
            backgroundColor: "#3B818C",
            color: "white",
          },
          {
            label: "completed_new",
            value: "Completed",
            backgroundColor: "#1F4156",
            color: "white",
          },
        ],
      },
    },
    [columnIDs.order_id]: {
      ...columnParams,
      colId: columnIDs.order_id,
      headerName: "Order",
      field: columnIDs.order_id,
      menuTabs: ["filterMenuTab", "generalMenuTab"],
      width: 250,
      headerComponent: CustomHeaderComponent,
      filter: CustomFilterComponent,
      filterParams: {
        sortOptions: [
          {
            label: columnSortOptions.mhq_number_asc,
            name: "MHQ Number Ascending",
            checked: getSortStatus(columnSortOptions.mhq_number_asc, gridState),
            action: () =>
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.mhq_number_asc,
                  callback: refreshGrid,
                })
              ),
          },
          {
            label: columnSortOptions.mhq_number_dsc,
            name: "MHQ Number Descending",
            checked: getSortStatus(columnSortOptions.mhq_number_dsc, gridState),
            action: () =>
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.mhq_number_dsc,
                  callback: refreshGrid,
                })
              ),
          },
          {
            label: columnSortOptions.mol_number_asc,
            name: "MOL Number Ascending",
            checked: getSortStatus(columnSortOptions.mol_number_asc, gridState),
            action: () =>
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.mol_number_asc,
                  callback: refreshGrid,
                })
              ),
          },
          {
            label: columnSortOptions.mol_number_dsc,
            name: "MOL Number Descending",
            checked: getSortStatus(columnSortOptions.mol_number_dsc, gridState),
            action: () =>
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.mol_number_dsc,
                  callback: refreshGrid,
                })
              ),
          },
          {
            label: columnSortOptions.wire_number_asc,
            name: "Wire Number Ascending",
            checked: getSortStatus(
              columnSortOptions.wire_number_asc,
              gridState
            ),
            action: () =>
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.wire_number_asc,
                  callback: refreshGrid,
                })
              ),
          },
          {
            label: columnSortOptions.wire_number_dsc,
            name: "Wire Number Descending",
            checked: getSortStatus(
              columnSortOptions.wire_number_dsc,
              gridState
            ),
            action: () =>
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.wire_number_dsc,
                  callback: refreshGrid,
                })
              ),
          },
        ],
      },
    },
    [columnIDs.delivery_date]: {
      ...columnParams,
      colId: columnIDs.delivery_date,
      headerName: "Delivery",
      field: columnIDs.delivery_date,
      menuTabs: ["filterMenuTab", "generalMenuTab"],
      headerComponent: CustomHeaderComponent,
      cellRenderer: DateCellRenderer,
      width: 150,
      filter: CustomFilterComponent,
      filterParams: {
        filterOptions: [
          {
            rowId: "delivery_date",
            heading: "Delivery Date Range",
            filters: [
              {
                label: "Today",
                name: "Today",
              },
              {
                label: "Tomorrow",
                name: "Tomorrow",
              },
              {
                label: "This Week",
                name: "This Week",
              },
              {
                label: "Future",
                name: "Future",
              },
              {
                label: "This Month",
                name: "This Month",
              },
              {
                label: "CustomDatePicker",
                name: "Custom",
              },
            ],
          },
          {
            rowId: "timed_delivery",
            heading: "Timed Delivery",
            filters: [
              {
                label: "Timed Deliveries",
                name: "Timed Deliveries",
              },
              {
                label: "Rush Deliveries",
                name: "Rush Deliveries",
              },
            ],
          },
        ],
        sortOptions: [
          {
            label: columnSortOptions.delivery_date_asc,
            name: "Delivery Date Ascending",
            checked: getSortStatus(
              columnSortOptions.delivery_date_asc,
              gridState
            ),
            action: () =>
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.delivery_date_asc,
                  callback: refreshGrid,
                })
              ),
          },
          {
            label: columnSortOptions.delivery_date_dsc,
            name: "Delivery Date Descending",
            checked: getSortStatus(
              columnSortOptions.delivery_date_dsc,
              gridState
            ),
            action: () =>
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.delivery_date_dsc,
                  callback: refreshGrid,
                })
              ),
          },
          {
            label: columnSortOptions.timed_delivery_asc,
            name: "Timed Deliveries Ascending",
            checked: getSortStatus(
              columnSortOptions.timed_delivery_asc,
              gridState
            ),
            action: () =>
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.timed_delivery_asc,
                  callback: refreshGrid,
                })
              ),
          },
          {
            label: columnSortOptions.timed_delivery_dsc,
            name: "Timed Deliveries Descending",
            checked: getSortStatus(
              columnSortOptions.timed_delivery_dsc,
              gridState
            ),
            action: () =>
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.timed_delivery_dsc,
                  callback: refreshGrid,
                })
              ),
          },
        ],
      },
    },
    [columnIDs.customer]: {
      ...columnParams,
      colId: columnIDs.customer,
      headerName: "Customer",
      field: columnIDs.customer,
      menuTabs: ["filterMenuTab", "generalMenuTab"],
      width: 150,
      headerComponent: CustomHeaderComponent,
      cellRenderer: CustomerCellRenderer,
      filter: CustomFilterComponent,
      filterParams: {
        sortOptions: [
          {
            label: columnSortOptions.customer_first_name_asc,
            name: "First Name Ascending",
            checked: getSortStatus(
              columnSortOptions.customer_first_name_asc,
              gridState
            ),
            action: () =>
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.customer_first_name_asc,
                  callback: refreshGrid,
                })
              ),
          },
          {
            label: columnSortOptions.customer_first_name_dsc,
            name: "First Name Descending",
            checked: getSortStatus(
              columnSortOptions.customer_first_name_dsc,
              gridState
            ),
            action: () =>
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.customer_first_name_dsc,
                  callback: refreshGrid,
                })
              ),
          },
          {
            label: columnSortOptions.customer_last_name_asc,
            name: "Last Name Ascending",
            checked: getSortStatus(
              columnSortOptions.customer_last_name_asc,
              gridState
            ),
            action: () =>
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.customer_last_name_asc,
                  callback: refreshGrid,
                })
              ),
          },
          {
            label: columnSortOptions.customer_last_name_dsc,
            name: "Last Name Descending",
            checked: getSortStatus(
              columnSortOptions.customer_last_name_dsc,
              gridState
            ),
            action: () =>
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.customer_last_name_dsc,
                  callback: refreshGrid,
                })
              ),
          },
          {
            label: columnSortOptions.customer_email_name_asc,
            name: "Email Name Ascending",
            checked: getSortStatus(
              columnSortOptions.customer_email_name_asc,
              gridState
            ),
            action: () =>
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.customer_email_name_asc,
                  callback: refreshGrid,
                })
              ),
          },
          {
            label: columnSortOptions.customer_email_name_dsc,
            name: "Email Name Descending",
            checked: getSortStatus(
              columnSortOptions.customer_email_name_dsc,
              gridState
            ),
            action: () =>
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.customer_email_name_dsc,
                  callback: refreshGrid,
                })
              ),
          },
        ],
      },
    },
    [columnIDs.recipient]: {
      ...columnParams,
      colId: columnIDs.recipient,
      headerName: "Recipient",
      field: columnIDs.recipient,
      menuTabs: ["filterMenuTab", "generalMenuTab"],
      headerComponent: CustomHeaderComponent,
      cellRenderer: RecipientCellRenderer,
      filter: CustomFilterComponent,
      width: 300,
      filterParams: {
        sortOptions: [
          {
            label: columnSortOptions.recipient_first_name_asc,
            name: "First Name Ascending",
            checked: getSortStatus(
              columnSortOptions.recipient_first_name_asc,
              gridState
            ),
            action: () =>
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.recipient_first_name_asc,
                  callback: refreshGrid,
                })
              ),
          },
          {
            label: columnSortOptions.recipient_first_name_dsc,
            name: "First Name Descending",
            checked: getSortStatus(
              columnSortOptions.recipient_first_name_dsc,
              gridState
            ),
            action: () =>
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.recipient_first_name_dsc,
                  callback: refreshGrid,
                })
              ),
          },
          {
            label: columnSortOptions.recipient_last_name_asc,
            name: "Last Name Ascending",
            checked: getSortStatus(
              columnSortOptions.recipient_last_name_asc,
              gridState
            ),
            action: () =>
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.recipient_last_name_asc,
                  callback: refreshGrid,
                })
              ),
          },
          {
            label: columnSortOptions.recipient_last_name_dsc,
            name: "Last Name Descending",
            checked: getSortStatus(
              columnSortOptions.recipient_last_name_dsc,
              gridState
            ),
            action: () =>
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.recipient_last_name_dsc,
                  callback: refreshGrid,
                })
              ),
          },
          {
            label: columnSortOptions.recipient_address_asc,
            name: "Address Ascending",
            checked: getSortStatus(
              columnSortOptions.recipient_address_asc,
              gridState
            ),
            action: () =>
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.recipient_address_asc,
                  callback: refreshGrid,
                })
              ),
          },
          {
            label: columnSortOptions.recipient_address_dsc,
            name: "Address Descending",
            checked: getSortStatus(
              columnSortOptions.recipient_address_dsc,
              gridState
            ),
            action: () =>
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.recipient_address_dsc,
                  callback: refreshGrid,
                })
              ),
          },
          {
            label: columnSortOptions.recipient_location_name_asc,
            name: "Location Name Ascending",
            checked: getSortStatus(
              columnSortOptions.recipient_location_name_asc,
              gridState
            ),
            action: () =>
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.recipient_location_name_asc,
                  callback: refreshGrid,
                })
              ),
          },
          {
            label: columnSortOptions.recipient_location_name_dsc,
            name: "Location Name Descending",
            checked: getSortStatus(
              columnSortOptions.recipient_location_name_dsc,
              gridState
            ),
            action: () =>
              dispatch(
                setPageActions({
                  type: "saveColumnSort",
                  value: columnSortOptions.recipient_location_name_dsc,
                  callback: refreshGrid,
                })
              ),
          },
        ],
      },
    },
    [columnIDs.properties]: {
      ...columnParams,
      colId: columnIDs.properties,
      headerName: "Products",
      field: columnIDs.properties,
      cellRenderer: ProductCellRenderer,
      suppressMenu: true,
      width: 500,
    },
    [columnIDs.price]: {
      ...columnParams,
      colId: columnIDs.price,
      headerName: "Amount",
      field: columnIDs.price,
      cellRenderer: PriceCellRenderer,
      suppressMenu: true,
      width: 150,
    },
    [columnIDs.actions]: {
      ...columnParams,
      colId: columnIDs.actions,
      headerName: "Actions",
      field: columnIDs.actions,
      suppressMenu: true,
      width: 150,
      cellRenderer: ActionsCellRenderer,
    },
    [columnIDs.designer]: {
      ...columnParams,
      colId: columnIDs.designer,
      headerName: "Designer",
      field: "designer.name",
      hide: true,
      suppressMenu: true,
      suppressColumnsToolPanel: true,
      enableRowGroup: showDesigners,
      rowGroup: showDesigners,
      width: 150,
      cellRenderer: DesignerCellRenderer,
    },
  };
};

export const needsActionMappings = {
  fulfillment_error: {
    image: "replace-filler",
    color: "#622355",
    label: "Fulfillment Error",
  },
  payment_error: {
    image: "payment-error",
    color: "#602454",
    label: "Payment Error",
  },
  price_request_approved: {
    image: "approved-price",
    color: "#60A17B",
    label: "Price Approved",
  },
  date_request_approved: {
    image: "approved-date",
    color: "#60A17B",
    label: "Date Approved",
  },
  price_change_request: {
    image: "price",
    color: "#b50d81",
    label: "Approve Price",
  },
  date_change_request: {
    image: "date",
    color: "#b50d81",
    label: "Approve Date",
  },
  approve_request_denied: {
    image: "approve-request-denied",
    color: "#9B2F78",
    label: "Denied",
  },
  chat_message: {
    image: "chat",
    color: "#3E5C6D",
    label: "Chat Message",
  },
};

export const oldPrintActionKeys = {
  OrderDetail: "print",
  TriFoldCard: "triCardPrint",
  Invoice: "invoice",
  DesignerWorksheet: "designerWorksheetPrint",
  OrderMessages: "orderMessages",
};

export const newPrintActionKeys = {
  OrderDetail: "details_printed",
  TriFoldCard: "trifold_printed",
  Invoice: "invoice_printed",
  DesignerWorksheet: "worksheet_printed",
  OrderMessages: "order_messages",
};

export const meetBallActionKeys = {
  design: "In Design",
  designed: "Designed",
  "out-for-delivery": "Out for Delivery",
};
