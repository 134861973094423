import moment from "moment";
import { DELIVER_BY_REGEX } from "components/views/drawer/create-order/helper";

export const isDeliveryService = (value = "") =>
  (value && value === "DELIVERY_SERVICE") || false;

export const isFloristDelivered = (value = "") =>
  (value && value === "FLORIST_DELIVERED") || false;

export const isWireOrder = (value = "") =>
  (value && value === "FLORIST_PARTNER") || false;

export const isStorePickUpOrder = (value = "") =>
  (value && value === "STORE_PICKUP") || false;

export const isWalkinOrder = (value = "") =>
  (value && value === "WALK_IN") || false;

export const isMOLDelivered = (value = "") =>
  (value &&
    (value === "MOL_FLORIST_DELIVERED" || value === "FOL_FLORIST_DELIVERED")) ||
  false;

export const isIncomingWiredOrder = (value = "", direction) =>
  direction === "INBOUND" && (isWireOrder(value) || isMOLDelivered(value));

export const isPhoneOutOrder = (value = "") =>
  (value && value === "PHONE_OUT") || false;

export const isResidence = (value = "") =>
  (value && value === "Residence") || false;

export const convertToTimeZone = (date, tzString) => {
  let convTime = moment.utc(date).tz(tzString).format("YYYY-MM-DDTHH:mm:ss");
  let convDate = moment(convTime).toDate();
  return convDate;
};

export const isDoordashProvider = (value = "") =>
  (value && value === "doordash") || false;

// rounding time to nearest 30 min to show it in pickup time drop down
export const roundUpTime = (intervalMilliseconds, datetime) => {
  datetime = datetime || new Date();
  var modTicks = datetime.getTime() % intervalMilliseconds;
  var delta = modTicks === 0 ? 0 : datetime.getTime() - modTicks;
  delta += intervalMilliseconds;
  return new Date(delta);
};

export const handleSelectedPickupTimeIsBeforeCurrentTime = (
  newPickUpDate,
  pickUpDateTime,
  pickUpTimeWindow,
  setFieldValue
) => {
  let newPickUpTimeOnly = pickUpDateTime.split("T")[1].split(":")[0];
  let timeMorning = moment(pickUpTimeWindow[0] + ":00:00", "HH:mm:ss");
  let timeAfternoon = moment(pickUpTimeWindow[1] + ":00:00", "HH:mm:ss");
  let actualTimeOfCheck = moment(newPickUpTimeOnly + ":00:00", "HH:mm:ss");
  let differenceForMorning = moment.duration(
    timeMorning.diff(actualTimeOfCheck)
  );
  let differenceForAfternoon = moment.duration(
    timeAfternoon.diff(actualTimeOfCheck)
  );

  if (
    Math.abs(parseInt(differenceForMorning.asMinutes())) <=
    Math.abs(parseInt(differenceForAfternoon.asMinutes()))
  ) {
    setFieldValue(
      "pickUpDateTime",
      `${moment(newPickUpDate).format("YYYY-MM-DD")}T${
        pickUpTimeWindow[0] + ":00:00"
      }`
    );
  } else {
    setFieldValue(
      "pickUpDateTime",
      `${moment(newPickUpDate).format("YYYY-MM-DD")}T${
        pickUpTimeWindow[1] + ":00:00"
      }`
    );
  }
};

export const handlePickupTimeWindow = (
  pickUpTimeWindow,
  newPickUpDate,
  dsMinDate,
  currentTime,
  setFieldValue,
  pickUpDateTime,
  timezone
) => {
  if (pickUpTimeWindow.length == 2) {
    let isSameDay = moment(newPickUpDate).isSame(dsMinDate, "day");
    if (isSameDay) {
      let actualTime = "";
      let timeMorning = moment(pickUpTimeWindow[0] + ":00:00", "HH:mm:ss");
      let timeAfternoon = moment(pickUpTimeWindow[1] + ":00:00", "HH:mm:ss");
      if (currentTime.isAfter(timeMorning)) {
        if (currentTime.isAfter(timeAfternoon)) {
          let addOneDate = convertToTimeZone(
            moment().utc().add(1, "days").toDate(),
            timezone
          );
          setFieldValue(
            "newPickUpDate",
            moment(addOneDate).format("YYYY-MM-DD")
          );
          actualTime = pickUpTimeWindow[0] + ":00:00";
        } else {
          actualTime = pickUpTimeWindow[1] + ":00:00";
        }
        setFieldValue(
          "pickUpDateTime",
          `${moment(newPickUpDate).format("YYYY-MM-DD")}T${actualTime}`
        );
      } else {
        handleSelectedPickupTimeIsBeforeCurrentTime(
          newPickUpDate,
          pickUpDateTime,
          pickUpTimeWindow,
          setFieldValue
        );
      }
    } else {
      handleSelectedPickupTimeIsBeforeCurrentTime(
        newPickUpDate,
        pickUpDateTime,
        pickUpTimeWindow,
        setFieldValue
      );
    }
  } else if (pickUpTimeWindow.length == 1) {
    let isSameDay = moment(newPickUpDate).isSame(dsMinDate, "day");
    if (isSameDay) {
      let actualTime = "";
      let timeMorning = moment(pickUpTimeWindow[0] + ":00:00", "HH:mm:ss");

      if (currentTime.isAfter(timeMorning)) {
        let addOneDate = convertToTimeZone(
          moment().utc().add(1, "days").toDate(),
          timezone
        );
        setFieldValue("newPickUpDate", moment(addOneDate).format("YYYY-MM-DD"));
      } else {
        actualTime = pickUpTimeWindow[0] + ":00:00";
      }
      setFieldValue(
        "pickUpDateTime",
        `${moment(newPickUpDate).format("YYYY-MM-DD")}T${actualTime}`
      );
    } else {
      setFieldValue(
        "pickUpDateTime",
        `${moment(newPickUpDate).format("YYYY-MM-DD")}T${
          pickUpTimeWindow[0] + ":00:00"
        }`
      );
    }
  }
};

export const formatDeliveryInstructions = (
  existingInstructions = "",
  newInstructions = ""
) => {
  const timedInstruction =
    existingInstructions.match(DELIVER_BY_REGEX)?.[0] || "";

  if (!newInstructions) {
    return timedInstruction; // If newInstructions is empty, return only the timed instruction without any extra newline.
  }

  return `${
    timedInstruction
      ? timedInstruction + (timedInstruction.includes("\n") ? "" : "\n")
      : ""
  }${newInstructions}`;
};
