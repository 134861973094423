import * as Yup from "yup";
import moment from "moment";

export const getValidationSchemaPrice = (Localise, messages) => {
  return Yup.object().shape({
    catalogPriceMinimumPrice: Yup.string()
      .required(Localise(messages, "Please enter Price"))
      .matches(
        /^[1-9]\d*(\.\d+)?$/,
        Localise(messages, "Please enter valid Price")
      ),
  });
};

export const getValidationSchema = (Localise, messages) => {
  return Yup.object().shape({
    operation: Yup.string().required(
      Localise(messages, "Please select operation name")
    ),

    action: Yup.string().required(Localise(messages, "Please select action")),

    fieldValue: Yup.string()
      .required(Localise(messages, "Please enter price"))
      .matches(
        /^[1-9]\d*(\.\d+)?$/,
        Localise(messages, "Please enter non-zero price in decimals")
      ),
    endDate: Yup.string()
      .test(
        "endDate",
        Localise(messages, "End date is less than start date."),
        function (value) {
          const { startDate } = this.parent;
          const isEndDateAfterStart =
            !value || !moment(startDate).isAfter(value);
          if (isEndDateAfterStart) {
            return true;
          }
          return false;
        }
      )
      .required(Localise(messages, "Please select end date")),
  });
};
