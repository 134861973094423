export const getUIConfig = (productRulesData = {}) => {
  const initialValues = {
    action: "",
    fieldValue: "",
    startDate: "",
    endDate: "",
    operation: "",
    products: [],
    collections: [],
    addons: [],
    ...productRulesData,
  };

  return initialValues;
};

export const PriceMinimumProductsFilter = (Localise, messages) => [
  {
    label: Localise(messages, "My Catalog"),
    value: "local",
  },
  {
    label: Localise(messages, "Global Catalog"),
    value: "global",
  },
  {
    label: Localise(messages, "Active"),
    value: "active",
  },
  {
    label: Localise(messages, "Inactive"),
    value: "inactive",
  },
  {
    label: Localise(messages, "Sold Out"),
    value: "soldOut",
  },
  {
    label: Localise(messages, "Call For Price"),
    value: "callForPrice",
  },
  {
    label: Localise(messages, "In-Store Pickup Only"),
    value: "instore",
  },
  {
    label: Localise(messages, "Rush Delivery"),
    value: "rushDelivery",
  },
  {
    label: Localise(messages, "Local Delivery Only"),
    value: "localDelOnly",
  },
  {
    label: Localise(messages, "Dropship"),
    value: "dropship",
  },
  // {
  //   label: Localise(messages, "Codified"),
  //   value: "codified",
  // },
  {
    label: Localise(messages, "Excluded From Minimum"),
    value: "excludeFromMinimum",
  },
  {
    label: Localise(messages, "Included In Minimum"),
    value: "includeInMinimum",
  },
];
