import { createSlice } from "@reduxjs/toolkit";
import { SLICE_NAME as parentSlice } from "../constants";
import { SLICE_NAME as sliceName } from "./constants";

import uniq from "lodash/uniq";
import isArray from "lodash/isArray";

import UserProfileStorage from "library/storage/userProfile";
import AppSettingsStorage from "library/storage/appSettings";
import { Entitlements } from "library/utils/entitlements";
import { setReset as setShopSettingsReset } from "library/sagas/views/home/drawer/shop-settings/common/slice";

const Headings = {
  dashboard: "Catalog Dashboard",
  catalog: "Products",
  collection: "Collection",
  product: "Product",
  create_addon: "Create Add-On",
  create_product: "Create Product",
  catalogSettings: "Catalog Settings",
  rushDeliverySettings: "Shop Settings",
  collectionSettings: "Collection Settings",
  priceMinimumProducts: "Products Under Minimum",
  addProductsToCollection: "Add Products To Collection",
  addProductsToCatalog: "Add Products To Catalog",
  create: "Create",
  localSharedCatalogSettings: "Catalog Details",
  listCollections: "All Collections",
  priceAdjustments: "Scheduled Price Adjustments",
};

export const initialState = {
  status: "",
  api: {
    local: {},
    global: {},
    addons: {},
    collections: {},
    sharedCatalogs: [],
  },
  success: {
    local: false,
    global: false,
    addons: false,
    collections: false,
    catalogSettings: false,
    sharedCatalogs: false,
  },
  error: {
    local: false,
    global: false,
    addons: false,
    collections: false,
    catalogSettings: false,
    sharedCatalogs: false,
  },
  data: {
    catalogSettings: {
      allowNewProducts: false,
      isRulesFetched: false,
      catalogPriceMinimum: {
        price: "0.00",
        excludedProducts: [],
        priceMinimumProducts: [],
        scheduledPriceAdjustment: [],
      },
    },
    productExtraFilters: {
      flowerTypes: [],
      productColors: [],
    },
  },
  actions: {
    catalogPriceMinimum: {
      bulkActionType: "",
      selectedProducts: [],
      filters: [],
      productsLimit: 0,
      searchText: "",
    },
  },
  shopCode: "",
  isMainMemberCode: false,
  allowSyndicate: false,
  catalogManager: false,
  switches: {
    productActivity: false,
    sharedCatalogs: true,
    enableFulfillmentDates: true,
  },
  screenTitles: Headings,
  screen: {
    name: "dashboard",
    params: {},
  },
};

// Redux toolkit uses Immer internally for state immutability
const slice = createSlice({
  name: `view::${parentSlice}::${sliceName}`,
  initialState,
  reducers: {
    setInit: (state, action) => {
      const memberCodes = UserProfileStorage.getProfileMemberCodes();
      const userRole = UserProfileStorage.getRole();
      const permissions = (code) => AppSettingsStorage.getPermissions(code);

      /* shopCode will be received from shop-settings(catalog) & Mercury HQ Dashboard */
      const shopCode = action.payload;
      if (shopCode) {
        state.shopCode = shopCode;
        state.isMainMemberCode =
          shopCode === "all" || shopCode === memberCodes[0];
      }

      state.status = "progress";
      state.success = initialState.success;
      state.allowSyndicate =
        state.isMainMemberCode &&
        memberCodes?.length > 1 &&
        userRole !== "FTD_ADMIN";
      state.catalogManager =
        Entitlements?.CATALOG_MANAGEMENT in permissions([memberCodes[0]]);
    },
    setReset: (state) => {
      Object.assign(state, initialState);
    },
    setShopCode: (state, action) => {
      const memberCodes = UserProfileStorage.getProfileMemberCodes();
      const userRole = UserProfileStorage.getRole();
      const permissions = (code) => AppSettingsStorage.getPermissions(code);
      const isMainMemberCode =
        action.payload === "all" || action.payload === memberCodes[0];

      Object.assign(state, {
        ...initialState,
        shopCode: action.payload,
        screen: state.screen,
        screenTitles: state.screenTitles,
        isMainMemberCode: isMainMemberCode,
      });

      state.status = "progress";
      state.success = initialState.success;
      state.allowSyndicate =
        state.isMainMemberCode &&
        memberCodes?.length > 1 &&
        userRole !== "FTD_ADMIN";
      state.catalogManager =
        Entitlements?.CATALOG_MANAGEMENT in permissions([memberCodes[0]]);
    },
    setData: (state, action) => {
      const { section, content } = action.payload;
      state.data[section] = { ...content };
    },
    setAPIResponse: (state, action) => {
      const { section, content, patch } = action.payload;

      if (patch) {
        const { id, data, todo } = patch;

        if (todo === "bulkUpdate") {
          Object.keys(data).forEach((section) => {
            if (section === "sharedCatalogs")
              state.api[section] = [...data[section]];
            else state.api[section] = { ...data[section] };
          });
        } else if (todo === "delete") {
          const ids = isArray(id) ? id : [id];
          ids.forEach((key) => {
            const entry = state.api[section][key] || {};
            if (entry.group !== "mol") {
              delete state.api[section][key];
            }
          });
        } else {
          const ids = isArray(id) ? id : [id];
          ids.forEach((key) => {
            const prev = state.api[section][key] || {};
            state.api[section] = {
              ...state.api[section],
              [key]: {
                ...prev,
                ...data,
              },
            };
          });
        }
      } else {
        if (section === "sharedCatalogs") {
          state.api[section] = [...content];
        } else {
          content.forEach((entry) => {
            const key = section === "collections" ? "handle" : "productId";
            const id = entry[key];

            state.api[section] = {
              ...state.api[section],
              [id]: {
                ...state.api[section][id],
                ...entry,
              },
            };
          });
        }
      }

      if (section === "addons") {
        const addonCtlIndex = state.api.sharedCatalogs.findIndex(
          (ctl) => ctl.groupId === "addons"
        );
        if (state.api.sharedCatalogs[addonCtlIndex])
          state.api.sharedCatalogs[addonCtlIndex].products = Object.keys(
            state.api.addons
          );
      } else if (section === "local" || section === "global") {
        const allCtlIndex = state.api.sharedCatalogs.findIndex(
          (ctl) => ctl.groupId === "all_catalogs"
        );
        if (state.api.sharedCatalogs[allCtlIndex])
          state.api.sharedCatalogs[allCtlIndex].products = uniq([
            ...state.api.sharedCatalogs[allCtlIndex].products,
            ...Object.keys(state.api.local),
            ...Object.keys(state.api.global),
          ]);

        const localCtlIndex = state.api.sharedCatalogs.findIndex(
          (ctl) => ctl.groupId === "my_catalog"
        );
        if (state.api.sharedCatalogs[localCtlIndex])
          state.api.sharedCatalogs[localCtlIndex].products = uniq([
            ...Object.keys(state.api.local),
          ]);

        const globalCtlIndex = state.api.sharedCatalogs.findIndex(
          (ctl) => ctl.groupId === "global_catalog"
        );
        if (state.api.sharedCatalogs[globalCtlIndex])
          state.api.sharedCatalogs[globalCtlIndex].products = uniq([
            ...Object.keys(state.api.global),
          ]);

        // // HELPER LOG: Use this in case of any ambiguity
        // if (section === "local") {
        //   const localCtl = state.api.sharedCatalogs.find(
        //     (ctl) => ctl.groupId === "my_catalog"
        //   );
        //   Object.keys(state.api.local).forEach((p) => {
        //     if (!localCtl.products.includes(p))
        //       console.log("Missing local product:", p);
        //   });
        // }

        // if (section === "global") {
        //   const globalCtl = state.api.sharedCatalogs.find(
        //     (ctl) => ctl.groupId === "global_catalog"
        //   );
        //   Object.keys(state.api.global).forEach((p) => {
        //     if (!globalCtl.products.includes(p))
        //       console.log("Missing global product:", p);
        //   });
        // }
      }
    },
    setAPISuccess: (state, action) => {
      state.success[action.payload] = true;
      if (!Object.values(state.success).filter((val) => !val).length)
        state.status = "done";
    },
    setAPIError: (state, action) => {
      state.error[action.payload] = true;
    },
    setScreenTitle: (state, action) => {
      const { handle, value } = action.payload;

      if (handle && !state.screenTitles[handle])
        state.screenTitles[handle] = value;
    },
    navigateScreen: (state, action) => {
      const { name = "", params = {} } = action.payload;
      state.screen = {
        name,
        params,
      };
    },
    setSideCar: (state, action) => {
      state.screen.params.sideCar = action.payload;
    },
    setScreenParam: (state, action) => {
      const { name, value } = action.payload;
      state.screen.params[name] = value;
    },
    fetchProductCatalog: () => {},
    fetchSharedCatalogs: () => {},
    fetchSRPPrices: () => {},
    fetchCollections: () => {},
    fetchExtendedDataCall: () => {},

    fetchCatalogSettings: () => {},
    saveCatalogSettings: () => {},
    applyPriceAdjustmentRule: () => {},
    updateScheduledPriceAdjustment: () => {},
    setPriceMinimumAction: (state, action) => {
      const { type, value } = action.payload;
      if (type === "viewMore") {
        state.actions.catalogPriceMinimum.productsLimit =
          state.actions.catalogPriceMinimum.productsLimit + value;
      } else if (type === "search")
        state.actions.catalogPriceMinimum.searchText = value;
      else if (type === "filters") {
        state.actions.catalogPriceMinimum.filters = [
          ...new Set([].concat(...value)),
        ];
      } else state.actions.catalogPriceMinimum[type] = value;
    },
    setPriceMinimumProductSelection: (state, action) => {
      const productId = action.payload;
      if (productId === "all") {
        state.actions.catalogPriceMinimum.selectedProducts =
          state.data.catalogSettings.catalogPriceMinimum.priceMinimumProducts;
      } else if (productId) {
        if (
          state.actions.catalogPriceMinimum.selectedProducts.includes(productId)
        ) {
          const index =
            state.actions.catalogPriceMinimum.selectedProducts.findIndex(
              (pid) => pid === productId
            );
          state.actions.catalogPriceMinimum.selectedProducts.splice(index, 1);
        } else {
          state.actions.catalogPriceMinimum.selectedProducts = [
            ...state.actions.catalogPriceMinimum.selectedProducts,
            productId,
          ];
        }
      } else {
        state.actions.catalogPriceMinimum.selectedProducts = [];
      }
    },
    setProductExtraFilters: (state, action) => {
      const { productExtraFilters, flowerType, productColor } = action.payload;

      if (productExtraFilters) {
        state.data.productExtraFilters.flowerTypes = [
          ...new Set([
            ...state.data.productExtraFilters.flowerTypes,
            ...productExtraFilters.flowerTypes,
          ]),
        ];
        state.data.productExtraFilters.productColors = [
          ...new Set([
            ...state.data.productExtraFilters.productColors,
            ...productExtraFilters.colorTypes,
          ]),
        ];
      }
      if (flowerType) {
        state.data.productExtraFilters.flowerTypes = [
          ...new Set([
            ...state.data.productExtraFilters.flowerTypes,
            flowerType,
          ]),
        ];
        if (productColor) {
          state.data.productExtraFilters.productColors = [
            ...new Set([
              ...state.data.productExtraFilters.productColors,
              productColor,
            ]),
          ];
        }
      }
    },
    setBulkUpdates: () => {},
    createMyCatalog: () => {},
  },
  extraReducers: (builder) => {
    builder.addCase(setShopSettingsReset, (state) => {
      state.shopCode = "";
    });
  },
});

export const {
  setInit,
  setReset,
  setInitSuccess,
  setInitFailure,
  setAPIResponse,
  setBulkUpdates,
  setData,
  setAPISuccess,
  setAPIError,
  setShopCode,
  setSideCar,
  setScreenTitle,
  setScreenParam,
  navigateScreen,
  fetchProductCatalog,
  fetchSharedCatalogs,
  fetchSRPPrices,
  fetchCollections,
  fetchExtendedDataCall,
  fetchCatalogSettings,
  saveCatalogSettings,
  setPriceMinimumAction,
  setPriceMinimumProductSelection,
  applyPriceAdjustmentRule,
  updateScheduledPriceAdjustment,
  setProductExtraFilters,
  createMyCatalog,
} = slice.actions;

export default {
  [sliceName]: slice.reducer,
};
