import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createStackNavigator } from "@react-navigation/stack";

import Product from "./product";
import Catalog from "./catalog";
import Dashboard from "./dashboard";
import Collection from "./collection";
import ListCollections from "./list-collections";
import { getInitialRoute } from "./helper";
import { PriceAdjustmentBanner } from "./header";
import ScheduledPriceAdjustments from "./price-adjustments";

import {
  setReset,
  setInit,
} from "library/sagas/views/home/drawer/product-catalog/common/slice";
import {
  selectScreenTitles,
  selectScheduledPriceAdjustment,
  selectShopCode,
  selectAllowSyndicate,
} from "library/sagas/views/home/drawer/product-catalog/common/selector";
import moment from "moment";

const screenMap = {
  dashboard: Dashboard,
  catalog: Catalog,
  collection: Collection,
  product: Product,
  listCollections: ListCollections,
  priceAdjustments: ScheduledPriceAdjustments,
};

const Stack = createStackNavigator();

const ProductCatalog = ({ navigation }) => {
  const dispatch = useDispatch();
  const shopCode = useSelector(selectShopCode);
  const allowSyndicate = useSelector(selectAllowSyndicate);
  const screenTitles = useSelector(selectScreenTitles);
  const priceAdjustmentRules = useSelector(selectScheduledPriceAdjustment);
  const isPriceAdjustmentActive =
    priceAdjustmentRules.filter(
      (rule) =>
        moment().format("L") >= moment(rule.startDate).format("L") &&
        moment().format("L") <= moment(rule.endDate).format("L")
    )?.length > 0;

  useEffect(() => {
    // initial Route load
    let screen,
      params = {};
    ({ screen = "dashboard", params = {} } = getInitialRoute(navigation));

    /* Minor hack to accept the membercode selected in Mercury HQ dashboard */
    const initialCode =
      shopCode === "all" ? params?.member_code ?? "" : shopCode;

    navigation.navigate("product-catalog", {
      screen,
      params: { ...params, member_code: initialCode },
    });
    dispatch(setInit(initialCode));
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setReset());
    };
  }, []);

  return (
    <Stack.Navigator headerMode="none">
      {Object.keys(screenMap).map((screen) => {
        const Component = screenMap[screen];
        return (
          <Stack.Screen
            key={screen}
            name={screen}
            options={{ title: screenTitles[screen] }}
          >
            {(props) => (
              <>
                {isPriceAdjustmentActive && !allowSyndicate ? (
                  <PriceAdjustmentBanner />
                ) : null}
                <Component {...props} rootNavigation={navigation} />
              </>
            )}
          </Stack.Screen>
        );
      })}
    </Stack.Navigator>
  );
};

export default ProductCatalog;
