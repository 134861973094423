import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { View, Text, Platform } from "react-native";
import { Input, Icon } from "react-native-elements";
import { useRoute } from "@react-navigation/native";
import orderBy from "lodash/orderBy";
import toUpper from "lodash/toUpper";
import capitalize from "lodash/capitalize";
import isEmpty from "lodash/isEmpty";

import { BulkActionsPicker } from "./helper";
import {
  SearchResults,
  ConfirmModal,
  PrimaryButton,
  SelectAll,
  FilterResults,
  Currency,
} from "components/wrappers";
import { Picker, ToasterHandler, AutoComplete } from "components/elements";
import Search from "components/containers/search";
import Filters from "components/containers/listing-new/header/controls/filters";
import I18NContext from "library/contexts/i18N";
import { DeviceContext } from "library/contexts/appSettings";
import { navigateScreen } from "library/sagas/views/home/drawer/product-catalog/common/slice";
import {
  selectAllCollections,
  selectSharedCatalogIds,
  selectSharedCatalogTitles,
  selectScreenTitles,
  selectAllowSyndicate,
  selectShopCode,
} from "library/sagas/views/home/drawer/product-catalog/common/selector";
import { selectAppliedFilters } from "library/sagas/views/home/drawer/product-catalog/catalog/selector";
import { selectAllCollectionsFilters as selectListCollectionsFilters } from "library/sagas/views/home/drawer/product-catalog/list-collections/selector";
import { selectAppliedFilters as selectCollectionAppliedFilters } from "library/sagas/views/home/drawer/product-catalog/collection/selector";

import { setAction } from "library/sagas/views/home/drawer/product-catalog/catalog/slice";
import { setAction as setListCollectionActions } from "library/sagas/views/home/drawer/product-catalog/list-collections/slice";
import { setProductsAction } from "library/sagas/views/home/drawer/product-catalog/collection/slice";
import {
  AddonFilters,
  CatalogFilter,
  ProductsFilter,
  CollectionPageFilters,
} from "./ui-config";

import { fonts, colors } from "styles/theme";
import tw from "tailwind-rn";

export const BulkActionHeader = ({
  selectProductsSelected,
  setProductSelection,
  setBulkAction,
  screen,
  handle,
  name,
}) => {
  const dispatch = useDispatch();
  const shopCode = useSelector(selectShopCode);
  const { Localise, messages } = React.useContext(I18NContext);
  const { isMobile, isTablet } = React.useContext(DeviceContext);

  const selectedProducts = useSelector(selectProductsSelected);
  const allCollections = useSelector(selectAllCollections);
  const allowSyndicate = useSelector(selectAllowSyndicate);

  const [dropDownQuery, setDropDownQuery] = useState();
  const [selectedAll, setSelectAll] = useState(false);
  const [bulkActionType, setBulkActionType] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisibile] = useState(false);
  const [modalData, setModalData] = useState("");
  const [bulkValue, setBulkValue] = useState("");
  const [bulkType, setBulkType] = useState("");
  const [bulkProperty, setBulkProperty] = useState("");
  const [bulkOperation, setBulkOperation] = useState("");

  const collectionsData = orderBy(
    allCollections
      .filter((val) => val.handle !== "addons")
      .map((e) => ({
        label: Localise(messages, e.name.split(" ").map(capitalize).join(" ")),
        value: e.handle,
      })),
    "label",
    "asc"
  );
  const currency = Currency(shopCode);

  const dropDownData =
    handle === "addons"
      ? [{ label: "All Products", value: "[*]" }]
          .concat(collectionsData)
          .filter((e) => toUpper(e.label).includes(toUpper(dropDownQuery)))
      : collectionsData.filter((e) =>
          toUpper(e.label).includes(toUpper(dropDownQuery))
        );

  useEffect(() => resetHeader(), []);

  useEffect(() => {
    const { value: bulkActionEntry = "", modal: modalData } = bulkActionType;
    setModalData(modalData);
    const [property = "", value = "", operation = "", type = ""] =
      bulkActionEntry.split(/::/);

    setBulkProperty(property);
    setBulkOperation(operation);
    setBulkType(type);
    setBulkValue(value);
  }, [bulkActionType]);

  const resetHeader = () => {
    setLoading(false);
    setSelectAll(false);
    setBulkActionType({ label: "Take Action", value: "" });
    dispatch(setProductSelection(""));
  };

  const onContinueAction = () => {
    setModalVisibile(false);
    onApply(true);
  };

  const onAbortAction = () => {
    setModalVisibile(false);
  };

  const onApply = (approved) => {
    if (!approved) {
      setModalVisibile(true);
      return true;
    }

    setLoading(true);
    dispatch(
      setBulkAction({
        type: "bulkActionType",
        value: `${bulkProperty}::${bulkValue}::${bulkOperation}::${bulkType}`,
        resolve: (message) => {
          resetHeader();

          ToasterHandler(
            "nice",
            Localise(messages, message ? message : "Bulk update complete")
          );
        },
        reject: (message) => {
          resetHeader();

          ToasterHandler(
            "uh oh",
            Localise(
              messages,
              message ? message : `Your update didn‘t save, please try again.`
            )
          );
        },
      })
    );
  };

  return (
    <View
      style={[
        tw(
          `flex ${
            isMobile ? "flex-col justify-center" : "flex-row items-center "
          } mr-3`
        ),
        { zIndex: 10 },
      ]}
    >
      <View style={tw("flex flex-row items-center")}>
        <SelectAll
          value={!!selectedAll}
          onChange={(value) => {
            setSelectAll(!selectedAll);
            dispatch(setProductSelection(value ? "all" : ""));
          }}
        />
        {selectedProducts?.length > 0 ? (
          <Text
            onPress={() => {
              dispatch(setProductSelection(""));
              selectedAll && setSelectAll(!selectedAll);
            }}
            style={{ ...fonts.link1, marginLeft: 6 }}
          >
            Deselect
          </Text>
        ) : null}
      </View>
      <View
        style={tw(`flex flex-row items-center ${isMobile ? "ml-0" : "ml-2"}`)}
      >
        <BulkActionsPicker
          type={handle === "addons" ? handle : screen}
          entity={{ handle, name }}
          bulkActionType={bulkActionType}
          setBulkActionType={setBulkActionType}
        />
        {isMobile ? (
          <PrimaryButton
            style={{ padding: 10, width: 90 }}
            title={Localise(messages, "Apply")}
            action={
              allowSyndicate ||
              bulkOperation === "decrement" ||
              !isEmpty(modalData)
                ? () => setModalVisibile(!modalVisible)
                : () => onApply(true)
            }
            disabled={
              !selectedProducts.length ||
              !bulkActionType.value ||
              !bulkValue.length ||
              (["collections", "prices", "addons"].includes(bulkProperty) &&
                !bulkOperation)
            }
            loading={loading}
          />
        ) : null}
      </View>
      <View style={tw("flex flex-row items-center")}>
        {["catalog"].includes(screen) &&
        ["collections", "addons"].includes(bulkProperty) ? (
          <AutoComplete
            placeholder={
              bulkValue.length > 0
                ? `${bulkValue.length} ${Localise(messages, "Selected")}`
                : Localise(messages, "Select Collection(s)")
            }
            onBlur={() => {}}
            isMultiSelect={true}
            onChangeText={(val) => setDropDownQuery(val)}
            data={dropDownData}
            initialDataLength={dropDownData?.length}
            listDisplayValues={["label"]}
            onSelect={(selectedValue) => {
              const newValues = [...bulkValue];
              const index = newValues.indexOf(selectedValue.value);
              const unSelected = index >= 0;
              unSelected
                ? newValues.splice(index, 1)
                : newValues.push(selectedValue.value);

              setBulkValue(newValues);
            }}
            selectedValues={bulkValue}
            value={dropDownQuery}
            onOptionChange={() => {}}
            testID="addTo-collections-suggestions"
            showOnFocus={true}
            setFocusBack={true}
            outerContainerStyle={{
              paddingHorizontal: isMobile ? 0 : 10,
              width: 250,
              marginTop: isTablet ? 20 : 10,
            }}
          />
        ) : null}
        {bulkProperty === "prices" ? (
          <>
            <Picker
              containerStyle={{
                paddingHorizontal: 0,
                paddingLeft: isMobile ? 0 : 10,
                paddingBottom: isMobile ? 15 : 0,
                width: 200,
              }}
              items={[
                {
                  label: `Increase in $${currency}`,
                  value: "increment::dollar",
                },
                { label: "Increase in %", value: "increment::percentage" },
                {
                  label: `Decrease in $${currency}`,
                  value: "decrement::dollar",
                },
                { label: "Decrease in %", value: "decrement::percentage" },
              ]}
              placeholder={{
                label: Localise(messages, "Choose Action"),
                value: "",
              }}
              value={`${bulkOperation}::${bulkType}`}
              onValueChange={(e) => {
                const [operation, type] = e.split(/::/);
                setBulkOperation(operation);
                setBulkType(type);
                operation === "decrement"
                  ? setModalData({
                      primary: "Continue",
                      secondary: "Nevermind",
                      content: `${
                        allowSyndicate
                          ? "This change will apply to All shops and will override any local shop changes.\n\n"
                          : ""
                      }Pricing updates on Global Products cannot be below SRP. Any Global Product updates that fall below SRP will be changed to the SRP. Are you sure you want to continue with your price changes?`,
                    })
                  : setModalData(
                      allowSyndicate
                        ? {
                            primary: "Update",
                            secondary: "Nevermind",
                            content:
                              "This change will apply to All shops and will override any local shop changes.",
                          }
                        : {}
                    );
              }}
            />

            <Input
              containerStyle={{
                width: 100,
                marginTop: isMobile ? 9 : isTablet ? 25 : 7,
              }}
              leftIcon={
                bulkType !== "percentage" && (
                  <Icon
                    name={"currency-usd"}
                    type={"material-community"}
                    color={"rgba(110, 120, 170, 1)"}
                    size={12}
                  />
                )
              }
              rightIcon={
                bulkType === "dollar" ? (
                  <Text style={fonts.default}> {currency}</Text>
                ) : (
                  bulkType === "percentage" && (
                    <Icon
                      name={"percent"}
                      type={"font-awesome"}
                      color={"rgba(110, 120, 170, 1)"}
                      size={12}
                    />
                  )
                )
              }
              inputContainerStyle={{
                padding: bulkType === "percentage" ? 10 : 6,
              }}
              leftIconContainerStyle={{
                paddingRight: bulkType === "percentage" ? 5 : 0,
              }}
              onChange={(e) => {
                setBulkValue(
                  Platform.OS === "web"
                    ? e.currentTarget.value
                    : e.nativeEvent.text
                );
              }}
              value={bulkValue}
            />
          </>
        ) : null}
        {!isMobile ? (
          <PrimaryButton
            style={{ padding: 10, width: 90 }}
            title={Localise(messages, "Apply")}
            action={
              allowSyndicate ||
              bulkOperation === "decrement" ||
              !isEmpty(modalData)
                ? () => setModalVisibile(!modalVisible)
                : () => onApply(true)
            }
            disabled={
              !selectedProducts.length ||
              !bulkActionType.value ||
              !bulkValue.length ||
              (["collections", "prices", "addons"].includes(bulkProperty) &&
                !bulkOperation)
            }
            loading={loading}
          />
        ) : null}
      </View>
      <ConfirmModal
        modalVisible={!!modalVisible}
        handlePrimary={onContinueAction}
        handleSecondary={onAbortAction}
        data={{
          modal: modalData,
        }}
      />
    </View>
  );
};

export const PageHeader = ({ selectSearchText, setSearchText }) => {
  const dispatch = useDispatch();
  const { isMobile, isDesktop } = React.useContext(DeviceContext);
  const { Localise, messages } = React.useContext(I18NContext);
  const { name: screen, params: { handle } = {} } = useRoute();
  const screenTitles = useSelector(selectScreenTitles);
  const sharedCatalogIds = useSelector(selectSharedCatalogIds);
  const sharedCatalogTitles = useSelector(selectSharedCatalogTitles);

  const isCreateCollectionScreen =
    screen === "collection" && handle === "create_collection";
  const isCreateProductScreen =
    screen === "product" && handle.includes("create_");
  const isCreateCatalogScreen = screen === "catalog" && handle === "create";

  const showSearch =
    selectSearchText &&
    setSearchText &&
    !isCreateCollectionScreen &&
    !isCreateProductScreen &&
    !isCreateCatalogScreen;

  const showFilters =
    screen !== "product" &&
    !isCreateCollectionScreen &&
    screen !== "dashboard" &&
    !isCreateCatalogScreen;

  const searchProps = {
    type: "fullWidth",
    containerStyle: {
      flex: 1,
      paddingHorizontal: 0,
      paddingRight: 10,
      maxWidth:
        screen === "catalog" || screen === "listCollections"
          ? !isMobile
            ? 400
            : 300
          : screen === "collection"
          ? !isMobile
            ? 630
            : 300
          : 676,
    },
    icon: "search",
    placeholder: Localise(messages, "Search"),
    selectors: {
      selectValue: selectSearchText,
    },
    actions: {
      setAction: setSearchText,
    },
  };

  const handleNavigation = (name, params) => {
    dispatch(navigateScreen({ name, params }));
  };

  const setProductsActionCollection = (payload) =>
    setProductsAction({ ...payload, section: "current" });

  const setActionCatalog = (payload) =>
    setAction({ ...payload, section: "current" });

  const filterProps = {
    width: "5%",
    icon: "filter",
    options: {
      "Filter By":
        handle === "addons"
          ? AddonFilters(Localise, messages)
          : screen === "collection"
          ? ProductsFilter(Localise, messages)
          : screen === "listCollections"
          ? CollectionPageFilters(Localise, messages)
          : CatalogFilter(Localise, messages),
    },
    selectors: {
      selectValue:
        screen === "collection"
          ? selectCollectionAppliedFilters("current")
          : screen === "listCollections"
          ? selectListCollectionsFilters
          : selectAppliedFilters,
    },
    actions: {
      setAction:
        screen === "collection"
          ? setProductsActionCollection
          : screen === "listCollections"
          ? setListCollectionActions
          : setActionCatalog,
    },
  };

  const selectedvalue =
    screen === "collection"
      ? selectCollectionAppliedFilters("current")
      : screen === "listCollections"
      ? selectListCollectionsFilters
      : selectAppliedFilters;

  const setActions =
    screen === "collection"
      ? setProductsActionCollection
      : screen === "listCollections"
      ? setListCollectionActions
      : setActionCatalog;

  return (
    <View style={tw("flex flex-col pb-2")}>
      <View style={tw(`flex flex-col items-baseline`)}>
        <View style={tw(`flex flex-col  w-full ${showSearch ? "mb-2" : ""}`)}>
          <View
            style={tw(`flex ${isMobile ? "flex-col" : "flex-row"}  w-full`)}
          >
            {(screen === "catalog" && handle !== "create") ||
            screen === "listCollections" ? (
              <View
                style={[
                  tw("flex flex-row items-center justify-start pb-1 pr-2"),
                ]}
              >
                <Text style={[tw("pr-1"), fonts.default]}>
                  {Localise(messages, "Viewing")}
                </Text>
                <Picker
                  containerStyle={{
                    paddingHorizontal: 0,
                    paddingBottom: 0,
                    width: 160,
                    marginLeft: isMobile ? 12 : 0,
                  }}
                  items={[...sharedCatalogIds, "listCollections"].map(
                    (e, index) => ({
                      key: index,
                      value: e,
                      label: Localise(
                        messages,
                        screenTitles[e] || sharedCatalogTitles[e]
                      ),
                    })
                  )}
                  placeholder={{}}
                  value={handle}
                  onValueChange={(e) => {
                    if (e !== handle) {
                      handleNavigation(
                        e === "listCollections" ? "listCollections" : "catalog",
                        { handle: e }
                      );
                      dispatch(setAction({ type: "catalogType", value: e }));
                    }
                  }}
                ></Picker>
              </View>
            ) : null}

            <View style={tw("flex flex-row w-full")}>
              {showSearch && <Search {...searchProps} />}
              {showFilters ? (
                <View style={tw("flex flex-row mt-2 ml-1")}>
                  <Filters {...filterProps} />
                </View>
              ) : null}
            </View>
          </View>
          <View style={tw("flex flex-row justify-between items-center")}>
            {showSearch && (
              <SearchResults
                selectSearchText={selectSearchText}
                setSearchText={setSearchText}
              />
            )}
            {showFilters && isDesktop ? (
              <View style={tw("flex flex-row flex-wrap mt-2")}>
                <FilterResults input={selectedvalue} action={setActions} />
              </View>
            ) : null}
          </View>
        </View>
      </View>
    </View>
  );
};

export const PriceAdjustmentBanner = () => {
  const { Localise, messages } = React.useContext(I18NContext);
  const { isMobile } = React.useContext(DeviceContext);
  const dispatch = useDispatch();
  return (
    <View
      style={[
        tw("flex flex-row items-center justify-center"),
        {
          backgroundColor: colors.priceBanner,
          height: 35,
          paddingRight: !isMobile ? 53 : 0,
        },
      ]}
    >
      <Text
        style={[
          tw("flex flex-row items-center justify-center"),
          {
            ...fonts.heading4,
            fontSize: 14,
          },
        ]}
      >
        {Localise(messages, "Adjusted Price Active.")}{" "}
        <Text
          style={{
            ...fonts.heading4,
            fontSize: 14,
            textDecoration: "underline",
          }}
          onPress={() =>
            dispatch(
              navigateScreen({
                name: "priceAdjustments",
                params: { handle: "rules" },
              })
            )
          }
        >
          {Localise(messages, "Edit Price Adjustments")}
        </Text>
      </Text>
    </View>
  );
};
