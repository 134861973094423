import {
  all,
  select,
  put,
  fork,
  call,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import request from "../request";
import UserProfileStorage from "library/storage/userProfile";
import { memberEntitlements } from "library/utils/entitlements";
import * as Navigation from "library/utils/navigation.js";

import {
  fetchCollection,
  createCollection,
  updateCollection,
  deleteCollection,
  setAPIResponse,
  setAPIError,
  setProductsData,
  patchCollection,
  setQuickAction,
  setProductsAction,
  resetProductsAction,
  setProductsSelection,
  revertCollectionToGlobal,
} from "./slice";
import {
  selectAPIResponse,
  selectSearchText,
  selectShopifySortOption,
  selectProductsSortOrder,
  selectProductsSelected,
  selectMHQSortOption,
  selectProductsData,
  selectAppliedFilters,
} from "./selector";
import { selectAllAddons } from "../common/selector";
import { selectWebsiteSettings } from "library/sagas/views/home/drawer/mercury-online/selector";
import {
  setAPIResponse as setCommonAPIResponse,
  setBulkUpdates,
  navigateScreen,
} from "../common/slice";
import {
  selectProductLookup,
  selectShopCode,
  selectAllowSyndicate,
} from "../common/selector";
import { processCollectionsResponse } from "../common/index.js";
import { handleFetchWebsiteSettings } from "../../mercury-online/website-settings";
import { selectProductsSelected as selectAddonsProductsSelection } from "library/sagas/views/home/drawer/product-catalog/catalog/selector";
import { replacePlaceHolders } from "library/sagas/views/home/drawer/helper";
import { setData as setCatalogData } from "../catalog/slice";
import get from "lodash/get";
import pullAt from "lodash/pullAt";
import orderBy from "lodash/orderBy";
import isEmpty from "lodash/isEmpty";
import toLower from "lodash/toLower";
import difference from "lodash/difference";
import intersection from "lodash/intersection";

function* handleFetchCollection(action = {}) {
  const shopCode = yield select(selectShopCode);
  const serviceRequest = (params) => request("get-collection", params);
  const handle = get(action, "payload", "");
  let response = {};

  try {
    let collection = {};
    if (handle) {
      yield call(handleFetchWebsiteSettings);

      const { content: websiteSettings = {} } = yield select(
        selectWebsiteSettings
      );
      const addonsData = yield select(selectAllAddons);

      response = yield call(serviceRequest, {
        handle,
        shopCode,
      });

      if (!(response && response.content && response.content.length > 0))
        throw "INVALID_RESPONSE";

      collection = processCollectionResponse(
        handle,
        response,
        websiteSettings,
        shopCode,
        addonsData
      );
    }

    const sortValue = yield select(selectMHQSortOption(collection.sortOption));
    collection.MHQSortValue = sortValue;

    yield put(setAPIResponse({ content: collection }));

    return response;
  } catch (error) {
    const memberCodes = UserProfileStorage.getProfileMemberCodes();
    const code = shopCode === "all" ? memberCodes[0] : shopCode;
    const collectionError =
      error === "INVALID_RESPONSE"
        ? `There is no collection "${handle}" available for member "${code}"`
        : "Something went wrong, please try again";
    yield put(setAPIError(collectionError));
  }
}

function* handleCreateCollection(action = {}) {
  const shopCode = yield select(selectShopCode);
  const serviceRequest = (params) => request("create-collection", params);

  const { resolve, reject, params = {} } = get(action, "payload", {});

  try {
    const uploadedImage = yield call(handleCollectionImagesUpload, params);

    const requestPayload = prepareSaveCollectionPayload({
      ...params,
      bannerImage: uploadedImage,
      params,
      shopCode,
    });

    const result = yield call(serviceRequest, { requestPayload, shopCode });
    const { handle } = get(result, "0", {});

    const response = yield call(handleFetchCollection, {
      payload: handle,
      shopCode,
    });

    yield put(
      setCommonAPIResponse({
        section: "collections",
        patch: {
          id: handle,
          todo: "create",
          data: get(processCollectionsResponse(response), "0", ""),
        },
      })
    );

    yield put(
      navigateScreen({
        name: "collection",
        params: {
          handle: handle,
        },
      })
    );

    resolve && resolve();
  } catch (error) {
    yield put(
      setAPIError(
        error.includes("already exists")
          ? "Collection name already Exits! Please try a different name"
          : "Something went wrong, please try again"
      )
    );
    reject && reject();
  }
}

function* handleUpdateCollection(action = {}) {
  const shopCode = yield select(selectShopCode);
  const serviceRequest = (params) => request("update-collection", params);

  const { resolve, reject, params = {} } = get(action, "payload", {});

  try {
    const uploadedImage = yield call(handleCollectionImagesUpload, params);

    const requestPayload = prepareSaveCollectionPayload({
      ...params,
      bannerImage: uploadedImage,
      shopCode,
    });

    yield call(serviceRequest, { requestPayload, shopCode });

    const response = yield call(handleFetchCollection, {
      payload: params.handle,
      shopCode,
    });
    if (params.handle !== "addons") {
      yield put(
        setCommonAPIResponse({
          section: "collections",
          patch: {
            id: params.handle,
            todo: "update",
            data: get(processCollectionsResponse(response), "0", ""),
          },
        })
      );
    }

    resolve && resolve();
  } catch (error) {
    yield put(setAPIError("Something went wrong, please try again"));
    reject && reject();
  }
}

function* handlePatchCollection(action = {}) {
  const shopCode = yield select(selectShopCode);
  const {
    resolve,
    reject,
    params: { handle, ...patch },
  } = get(action, "payload", {});

  const fetchServiceRequest = (params) => request("get-collection", params);

  try {
    const response = yield call(fetchServiceRequest, {
      handle,
      shopCode,
    });

    const params = {
      ...processCollectionResponse(handle, response),
      ...patch,
    };

    yield put(updateCollection({ params, resolve, reject }));
  } catch (error) {
    yield put(setAPIError("Something went wrong, please try again"));
    reject && reject();
  }
}

function* handleDeleteCollection(action = {}) {
  const shopCode = yield select(selectShopCode);
  const allowSyndicate = yield select(selectAllowSyndicate);
  const serviceRequest = (params) => request("delete-collection", params);
  const {
    resolve,
    reject,
    params: { id, handle },
  } = get(action, "payload", {});

  try {
    yield call(serviceRequest, {
      collectionId: id,
      shopCode,
      ...(allowSyndicate ? { handle } : {}),
    });

    yield put(
      setCommonAPIResponse({
        section: "collections",
        patch: {
          id: handle,
          todo: "delete",
        },
      })
    );

    resolve && resolve();
  } catch (error) {
    yield put(setAPIError("Something went wrong, please try again"));
    reject && reject();
  }
}

function* handleRevertCollectionToGlobal(action = {}) {
  const shopCode = yield select(selectShopCode);
  const serviceRequest = (params) =>
    request("collection-revert-to-global", params);

  const { resolve, reject, params = {} } = get(action, "payload", {});
  const { handle } = params;

  try {
    yield call(serviceRequest, { handle, shopCode });

    const response = yield call(handleFetchCollection, {
      payload: handle,
      shopCode,
    });

    if (handle !== "addons") {
      yield put(
        setCommonAPIResponse({
          section: "collections",
          patch: {
            id: handle,
            todo: "update",
            data: get(processCollectionsResponse(response), "0", ""),
          },
        })
      );
    }

    resolve && resolve();
  } catch (error) {
    yield put(setAPIError("Something went wrong, please try again"));
    reject && reject();
  }
}

function* handleQuickAction(action = {}) {
  const {
    resolve,
    reject,
    params: {
      id,
      handle,
      patch: { todo, data },
    },
  } = get(action, "payload", {});

  if (!id) return;

  if (todo === "delete") {
    yield put(deleteCollection({ params: { id, handle }, resolve, reject }));
  } else if (todo === "removeFromCollection") {
    yield put(
      setProductsSelection({
        section: "current",
        productId: id,
        forceAdd: true,
      })
    );
    yield call(
      handleBulkUpdates,
      "current",
      `collections::${data.collection}::remove`,
      resolve,
      reject
    );
  } else {
    yield put(
      patchCollection({ params: { handle, ...data }, resolve, reject })
    );
  }
}

function* handleDataRefresh(action = {}) {
  const { section = "global", silent } = get(action, "payload", {});

  if (silent) return;

  try {
    const { name: screen, params: { handle = "" } = {} } =
      yield Navigation.getCurrentRoute(true);

    const isAddonScreen = handle === "addons";
    if (
      (screen === "collection" || isAddonScreen) &&
      (section === "local" || section === "global")
    ) {
      yield call(handleProcessData);
    }
  } catch (error) {
    //Do Nothing
  }
}

function* handleUIRefresh(action = {}) {
  const {
    section,
    type: actionType,
    value: actionValue,
    resolve,
    reject,
  } = get(action, "payload", {});

  const {
    name: screen,
    params: { handle = "" },
  } = yield Navigation.getCurrentRoute(true);
  if (screen === "collection" || handle === "addons") {
    const { handle, sortOption: currentSort } = yield select(selectAPIResponse);

    // bulk action applied
    if (actionType === "bulkActionType") {
      yield call(handleBulkUpdates, section, actionValue, resolve, reject);

      // sort applied
    } else if (actionType === "sortOrder" && section === "current") {
      const sortOption = yield select(selectShopifySortOption);

      if (
        sortOption &&
        ((currentSort && currentSort !== sortOption) || sortOption === "Manual")
      ) {
        let productIds = [];

        if (sortOption === "Manual" || handle === "addons") {
          productIds = yield call(handleProcessData, "current");
        }
        if (handle)
          yield put(
            patchCollection({
              params: {
                handle,
                sortOption,
                productIds,
              },
              resolve,
              reject,
            })
          );
      }
    } else {
      yield call(handleProcessData, section);
    }
  }
}

/** Helpers */
function* handleBulkUpdates(section, actionValue, resolve, reject) {
  const shopCode = yield select(selectShopCode);
  const serviceRequest = (params) => request("bulk-products-update", params);

  try {
    if (!actionValue) return;

    const selectedProducts = yield select(selectProductsSelected(section));
    const [property, value, operation, type] = actionValue.split(/::/);

    const final =
      property === "prices"
        ? parseFloat(value)
        : property === "collections"
        ? value.split(/,/)
        : value === "true";

    yield call(serviceRequest, {
      productIds: selectedProducts,
      property,
      operation,
      value: final,
      type,
      shopCode,
    });

    yield fork(setBulkLocalDataPatchHandler, {
      section,
      productIds: selectedProducts,
      property,
      operation,
      value,
      type,
    });

    yield put(
      setBulkUpdates({
        productIds: selectedProducts,
        property,
        operation,
        value: final,
        type,
      })
    );

    yield put(setProductsSelection({ section, productId: "" }));

    resolve && resolve();

    yield put(
      resetProductsAction({
        section,
        type: "bulkActionType",
        value: "",
      })
    );
  } catch {
    reject && reject();
  }
}

// update collection changes locally into slice (redux)
function* setBulkLocalDataPatchHandler({
  section,
  productIds,
  property,
  operation,
  value,
}) {
  const collection = yield select(selectAPIResponse);
  const productLookup = yield select(selectProductLookup);
  const currentProductsData = yield select(selectProductsData("current"));

  let patchData = {};
  patchData.totalActiveProductsCount = collection?.totalActiveProductCount ?? 0;

  if (property === "collections") {
    patchData.productIds =
      operation === "add"
        ? collection.productIds.concat(productIds)
        : difference(collection.productIds, productIds);
    patchData.totalProductsCount = patchData.productIds.length;
    patchData.productIds.forEach((pid) => {
      const product = productLookup[pid] || {};
      const status = product.status === "active";
      if (status) patchData.totalActiveProductsCount++;
    });
  } else if (property === "active" && section === "current") {
    currentProductsData.forEach((pid) => {
      const product = productLookup[pid] || {};
      const newStatus = productIds.includes(pid)
        ? product.statusAsOnDate && value
          ? "active"
          : "inactive"
        : product.status;

      if (newStatus === "active") patchData.totalActiveProductsCount++;
    });
  }

  if (!isEmpty(patchData))
    yield put(
      setAPIResponse({
        patch: patchData,
      })
    );
}

function* handleProcessData(section) {
  const productLookup = yield select(selectProductLookup);
  const {
    productIds = [],
    handle = "",
    manualSortProductOrder = [],
  } = yield select(selectAPIResponse);
  const selectedProductIds =
    handle !== "addons"
      ? yield select(selectProductsSelected("current"))
      : yield select(selectAddonsProductsSelection);
  const { sortOrder, manualSortOrder } = yield select(selectProductsSortOrder);

  try {
    let productArray = [];
    let reOrderedData = [];
    if (handle === "addons" && manualSortProductOrder.length > 0) {
      reOrderedData = [
        ...manualSortProductOrder.filter((item) => productIds.includes(item)),
        ...productIds.filter((item) => !manualSortProductOrder.includes(item)),
      ];

      productArray = reOrderedData;
    } else {
      productArray = productIds;
    }

    const orderedProducts = applySort(
      productArray,
      productLookup,
      sortOrder,
      manualSortOrder,
      selectedProductIds,
      handle,
      reOrderedData
    );

    let products = {
      current: orderedProducts,
      new: Object.values(productLookup).filter(
        (p) => !productIds.includes(p.productId) && p.catalogType !== "addons"
      ),
    };

    const sections = section ? [section] : ["current", "new"];

    // apply search & filters
    const results = yield all(
      sections.map((section) => call(applyControls, products[section], section))
    );
    if (handle === "addons") {
      const productIds = results[0].map((p) => p.productId);
      yield put(
        setCatalogData({
          data: productIds,
        })
      );
    } else {
      yield all(
        sections.map((section, index) => {
          const productIds = results[index].map((p) => p.productId);
          return put(
            setProductsData({
              section,
              data: productIds,
            })
          );
        })
      );
    }
    return orderedProducts.map((p) => p.productId);
  } catch {
    // Do nothing
  }
}

function* handleCollectionImagesUpload(params) {
  const memberCodes = UserProfileStorage.getProfileMemberCodes();
  const shopCode = yield select(selectShopCode);
  const memberCode = shopCode === "all" ? memberCodes[0] : shopCode;
  const imageUploadRequest = (handle, image) => {
    let contentType = "text/plain";
    let fileExt = "";

    if (image.startsWith("data:image")) {
      fileExt = image.substring("data:image/".length, image.indexOf(";base64"));
      contentType = `image/${fileExt}`;
    }

    return request("upload-MOL-image", {
      imageContent: image.split(",")[1],
      imageName: `${handle}_Image.${fileExt}`,
      imageCategory: "product",
      imageContentType: contentType,
      shopCode: memberCode,
    })
      .then((response) => {
        return get(response, "imageDetails.GCPImageURL", "");
      })
      .catch(() => {
        return "";
      });
  };

  try {
    const { handle, bannerImage } = params;

    if (bannerImage.startsWith("data:image")) {
      const result = yield call(imageUploadRequest, handle, bannerImage);
      return result.startsWith("data:image") ? "" : result;
    }

    return bannerImage;
  } catch {
    yield put(
      setAPIError({
        section: "collection",
        error: "Something went wrong, please try again",
      })
    );

    return "";
  }
}

function* applyControls(data = [], section) {
  const searchText = section ? yield select(selectSearchText(section)) : "";
  const filters = section ? yield select(selectAppliedFilters(section)) : [];

  // apply search
  let results = data
    .filter(
      (p) =>
        toLower(p.name).includes(toLower(searchText)) ||
        toLower(p.description).includes(toLower(searchText)) ||
        toLower(p.productId).includes(toLower(searchText)) ||
        toLower(p.variationDescription).includes(toLower(searchText)) ||
        toLower(p.flowerType).includes(toLower(searchText)) ||
        toLower(p.color).includes(toLower(searchText))
    )
    .map((p, index) => ({ ...p, position: index + 1 }));

  // apply filters
  if (filters.length) {
    results = applyFilters(results, filters);
  }

  return results;
}

const applyFilters = (data = [], filters) =>
  data.filter((entry) => {
    let matchesFilter = true;
    if (filters.length) {
      const filterValues = filters.map((e) => e.value);

      if (
        matchesFilter &&
        (filterValues.includes("local") || filterValues.includes("global"))
      ) {
        if (filterValues.includes("local") && entry.catalogType === "local")
          matchesFilter = true;
        else if (
          filterValues.includes("global") &&
          entry.catalogType === "global"
        )
          matchesFilter = true;
        else matchesFilter = false;
      }

      if (
        matchesFilter &&
        (filterValues.includes("active") || filterValues.includes("inactive"))
      ) {
        if (filterValues.includes("active") && entry.status === "active")
          matchesFilter = true;
        else if (
          filterValues.includes("inactive") &&
          entry.status === "inactive"
        )
          matchesFilter = true;
        else matchesFilter = false;
      }

      if (matchesFilter && filterValues.includes("soldOut")) {
        if (filterValues.includes("soldOut") && entry.soldOut)
          matchesFilter = true;
        else matchesFilter = false;
      }

      if (
        matchesFilter &&
        (filterValues.includes("callForPrice") ||
          filterValues.includes("instore") ||
          filterValues.includes("rushDelivery") ||
          filterValues.includes("localDelOnly") ||
          filterValues.includes("dropship"))
      ) {
        if (
          (filterValues.includes("callForPrice") && entry.callForPrice) ||
          (filterValues.includes("instore") && entry.inStorePickUp) ||
          (filterValues.includes("rushDelivery") &&
            !entry.excludeFromRushDelivery) ||
          (filterValues.includes("localDelOnly") && entry.localDelOnly) ||
          (filterValues.includes("dropship") && entry.dropShippingProduct)
        ) {
          matchesFilter = true;
        } else matchesFilter = false;
      }

      // if (matchesFilter && filterValues.includes("codified")) {
      //   if (entry.codified) matchesFilter = true;
      //   else matchesFilter = false;
      // }
    }

    return matchesFilter;
  });

const applySort = (
  productIds,
  productLookup,
  regularSortOrder,
  manualSortOrder,
  selectedPids
) => {
  let orderedProductIds = productIds;
  let orderedProducts = [];

  if (manualSortOrder) {
    const [manualSortType, extraValue] = manualSortOrder.split(/::/);
    const selectedProductIndexes = selectedPids.map((p) =>
      productIds.indexOf(p)
    );
    const sourceProducts = [...productIds];
    const targetProducts = pullAt(sourceProducts, selectedProductIndexes);

    if (manualSortType === "moveToTop") {
      orderedProductIds = [...targetProducts, ...sourceProducts];
    } else if (manualSortType === "moveToBottom") {
      orderedProductIds = [...sourceProducts, ...targetProducts];
    } else if (manualSortType === "moveToPos") {
      sourceProducts.splice(extraValue - 1, 0, ...targetProducts);
      orderedProductIds = sourceProducts;
    } else if (manualSortType === "dragToPos") {
      orderedProductIds = extraValue.split(/,/);
    }
  }

  orderedProductIds.forEach((pid) => {
    const product = productLookup[pid] || {};
    const isProductExists = Object.keys(product).length;
    if (isProductExists) orderedProducts.push({ ...product });
  });

  if (regularSortOrder) {
    const [sortField, sortOrderBy] = regularSortOrder.split(/::/);
    if (sortField === "name" || sortField === "price")
      return orderBy(
        orderedProducts,
        (e) => {
          const value = e[sortField];
          return sortField === "price" ? parseFloat(value) : toLower(value);
        },
        sortOrderBy
      );
  }

  return orderedProducts;
};

const getSEO = (string) =>
  toLower(string.trim())
    .replace(/[^a-zA-Z0-9- ]/g, "")
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-");

const getShopifyWebsiteUrl = (shopCode) => {
  const memberCodes = UserProfileStorage.getProfileMemberCodes();
  const code = shopCode === "all" ? memberCodes[0] : shopCode;
  const entitlement = UserProfileStorage.getMemberEntitlement(code);

  const showSeoDisplayUrl =
    entitlement &&
    entitlement
      .toLowerCase()
      .includes(memberEntitlements.MERCURY_ONLINE.toLowerCase());

  if (showSeoDisplayUrl) {
    const { shopify_store_url } = UserProfileStorage.getShopPreferences(code);

    return shopify_store_url;
  }

  return "";
};

const processCollectionResponse = (
  handle,
  response = {},
  websiteSettings = {},
  shopCode,
  addonsData = []
) => {
  const {
    productIds: regularSortProductOrder = [],
    manualSortProductOrder = [],
    productSortType = "Best Selling",
    ...collection
  } = get(response, "content", []).find((e) => e.handle === handle) || {};

  const addonProductIds = addonsData.map((val) => val.productId);
  const shopifyWebsiteUrl = getShopifyWebsiteUrl(shopCode);
  const productIds =
    productSortType === "Manual"
      ? intersection(manualSortProductOrder, regularSortProductOrder)
      : regularSortProductOrder;

  const result = {
    ...collection,
    seoHeader: replacePlaceHolders(collection.seoHeader, websiteSettings, true),
    seoFooter: replacePlaceHolders(collection.seoFooter, websiteSettings, true),
    seoPageTitle: replacePlaceHolders(
      collection.seoPageTitle,
      websiteSettings,
      true
    ),
    seoMetaDescription: replacePlaceHolders(
      collection.seoMetaDescription,
      websiteSettings,
      true
    ),
    bannerImage: get(collection, "media.bannerImages.0.url", ""),
    seoDisplayUrl: shopifyWebsiteUrl
      ? `${shopifyWebsiteUrl}/collections/${collection.handle}`
      : "",
    sortOption: productSortType,
    manualSortProductOrder,
    ...(collection?.handle === "addons"
      ? { productIds: addonProductIds }
      : { productIds }),
  };

  return result;
};

const prepareSaveCollectionPayload = (params) => {
  const {
    id = "",
    name = "",
    handle = "",
    status = "",
    seoUrl = "",
    seoHeader = "",
    seoFooter = "",
    seoPageTitle = "",
    seoMetaDescription = "",
    productIds = [],
    sortOption = "",
    bannerImage = "",
  } = params;

  const newHandle = getSEO(name);

  return {
    id,
    name,
    status,
    seoHeader,
    seoFooter,
    seoPageTitle,
    seoMetaDescription,
    manualSortProductOrder: sortOption === "Manual" ? productIds : [],
    ...(handle === "addons" ? { productIds } : {}),
    productSortType: sortOption,
    handle: handle || newHandle,
    seoUrl: seoUrl || `/collections/${handle || newHandle}`,
    ...(bannerImage && { media: { bannerImages: [{ url: bannerImage }] } }),
  };
};

/**
 * Watcher subscribes to FETCH_REQUEST actions
 */
function* watchSaga() {
  yield takeLatest(fetchCollection.type, handleFetchCollection);
  yield takeEvery(createCollection.type, handleCreateCollection);
  yield takeEvery(updateCollection.type, handleUpdateCollection);
  yield takeEvery(patchCollection.type, handlePatchCollection);
  yield takeEvery(deleteCollection.type, handleDeleteCollection);
  yield takeEvery(setQuickAction.type, handleQuickAction);
  yield takeLatest(setAPIResponse.type, handleUIRefresh);
  yield takeLatest(setCommonAPIResponse.type, handleDataRefresh);
  yield takeLatest(setProductsAction.type, handleUIRefresh);
  yield takeLatest(
    revertCollectionToGlobal.type,
    handleRevertCollectionToGlobal
  );
}

export default watchSaga;
