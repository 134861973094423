/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useContext,
} from "react";
import {
  Text,
  View,
  Image,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import { Input } from "react-native-elements";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "react-native-elements";

import {
  DeviceContext,
  AppSettingsContext,
} from "library/contexts/appSettings";
import UserProfileStorage from "library/storage/userProfile";
import { selectDashboardShopCode } from "library/sagas/ongoing/global-data/selector";
import {
  selectLoading,
  selectActions,
  selectPageLimit,
  selectDesigners,
} from "library/sagas/views/home/drawer/orders-new/selector";
import {
  setPageInitialise,
  setPageActions,
  fetchDesigners,
  fetchOrders,
  resetSlice,
  assignDesigner,
} from "library/sagas/views/home/drawer/orders-new/slice";
import { hasDesignCenterFeature } from "library/utils/featureAvailability";
import I18NContext from "library/contexts/i18N";
import PageStorage from "library/storage/pageStorage";

import {
  TabBarNew,
  CustomModal,
  PrintIframeRenderer,
  Tooltip,
} from "components/elements";
import { Form, FormFieldPicker } from "components/elements/forms";
import AGGridContainer from "components/containers/ag-grid";
import OrderDetails from "components/views/drawer/order-details";
import {
  ListOfActions,
  ListOfSubActions,
} from "components/views/drawer/order-listing/current-orders/config";

import IMAGES from "static/assets/images";
import { colors, backgroundColors } from "styles/theme";
import { fonts } from "styles/theme";

import debounce from "lodash/debounce";
import tw from "tailwind-rn";

import { orderGroupsConfig, columnDefs } from "./config";
import "./styles.css";

const OrdersNew = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectLoading);
  const shopCode = useSelector(selectDashboardShopCode);
  const pageLimit = useSelector(selectPageLimit);
  const designers = useSelector(selectDesigners);
  const {
    searchQuery = "",
    orderGroups = [],
    gridState = [],
    showDesigners = false,
  } = useSelector(selectActions);
  const gridRef = useRef(null);
  const formRef = useRef();
  const { isDesktop } = useContext(DeviceContext);
  const { permissions } = useContext(AppSettingsContext);
  const { messages, Localise } = useContext(I18NContext);

  const [isGridReset, setGridReset] = useState(false);
  const [orderDetailsData, setOrderDetailsData] = useState(null);
  const [resetModalVisible, setResetModalVisibile] = useState(false);
  const [columnDefination, setColumnDefination] = useState([]);
  const [showLimitMessage, setShowLimitMessage] = useState(false);

  let gridAdditionalProps = {};
  const { memberCodes = [] } = UserProfileStorage.getAuthGroup();
  const sendingMember = shopCode === "all" ? memberCodes[0] : shopCode;
  const columns = (
    Object.keys(columnDefination).length === gridState.length
      ? gridState
      : Object.keys(columnDefination)
  ).map((item) => {
    const key = typeof item === "string" ? item : item.colId;
    const col = gridState.find((e) => e.colId === key) || {};

    return {
      ...columnDefination[key],
      hide: col.hide ?? columnDefination[key]?.hide,
      width: col.width ?? columnDefination[key]?.width,
      appliedFilter: col.appliedFilter ?? columnDefination[key]?.appliedFilter,
      appliedSort: col.appliedSort ?? columnDefination[key]?.appliedSort,
      appliedSearch: col.appliedSearch ?? columnDefination[key]?.appliedSearch,
    };
  });
  const isEnhancedDesignerEnabled = memberCodes.some((eachshop) =>
    hasDesignCenterFeature(permissions, eachshop)
  );

  const saveGridState = debounce(() => {
    const gridCurrentState =
      gridRef?.current?.columnApi?.getColumnState() ?? [];
    isGridReset
      ? setGridReset(false)
      : dispatch(
          setPageActions({
            type: "saveGridState",
            value: gridCurrentState.map((e) => {
              const colId = e?.colId ?? "";

              return {
                ...(gridState.find((j) => j.colId === e.colId) || {}),
                colId,
                hide: e?.hide ?? columnDefination[colId]?.hide,
                width: e?.width ?? columnDefination[colId]?.width,
              };
            }),
          })
        );
  }, 100);

  const refreshGrid = () => {
    gridRef?.current?.api?.paginationGoToFirstPage();
    gridRef?.current?.api?.setServerSideDatasource(null);
    setGridDataSource();
  };

  const debouncedSearch = useCallback(
    debounce(() => {
      refreshGrid();
    }, 1000),
    []
  );

  const getChildCount = (data = {}) => {
    return data ? data.childCount : undefined;
  };

  const setGridDataSource = () => {
    const dataSource = {
      getRows: function (params) {
        gridAdditionalProps?.togglePaginationControls(false);
        dispatch(
          fetchOrders({
            sendingMember,
            offset: gridRef?.current?.api?.paginationGetCurrentPage() * 10,
            callback: ({ data = [], totalRows = 0 }) => {
              const rowGroupCols = params.request.rowGroupCols;
              const groupKeys = params.request.groupKeys;

              if (rowGroupCols.length) {
                setShowLimitMessage(totalRows > 1000);
                if (groupKeys.length) {
                  data = data
                    .map((e) => e.designer.name === groupKeys[0] && e)
                    .filter(Boolean);
                } else {
                  const rowGroups = [];

                  data.filter((e) => {
                    !rowGroups.map((d) => d.id).includes(e.designer.id) &&
                      rowGroups.push(e.designer);
                  });
                  data = rowGroups.map((rec) => ({
                    designer: rec,
                    group: true,
                    childCount: data.filter((i) => i.designer.id === rec.id)
                      .length,
                    childOrders: data.map((i) => i.id),
                  }));
                }
                params.successCallback(data, data.length);
              } else {
                setShowLimitMessage(false);
                params.successCallback(data, Number(totalRows));
              }

              gridAdditionalProps?.setLastRowOnPage(params.request.endRow);
              gridAdditionalProps?.togglePaginationControls(true);
            },
          })
        );
      },
    };

    gridRef?.current?.api?.setServerSideDatasource(dataSource);
  };

  const resetModalContent = {
    content: (
      <Text
        style={[
          tw("p-4"),
          { fontSize: 15, color: colors.highlighter, textAlign: "left" },
        ]}
      >
        {
          "Are you sure you want to reset your grid settings? This will: \n\n - Restore all columns to their default view and positions. \n - Remove any filters or sorting currently applied. \n - Clear your saved preferences. \n\n You can always customize your view again after the reset."
        }
      </Text>
    ),
    buttons: [
      { type: "secondary", title: "Cancel" },
      { type: "primary", title: "Reset Settings" },
    ],
  };

  useEffect(() => {
    showDesigners && dispatch(fetchDesigners({ memberCodes }));
    gridRef?.current?.api && saveGridState();
  }, [showDesigners]);

  useEffect(() => {
    setColumnDefination(
      columnDefs(dispatch, gridState, refreshGrid, showDesigners)
    );
  }, [gridState]);

  useEffect(() => {
    dispatch(setPageInitialise());
    setTimeout(() => {
      setGridDataSource();
    }, 0);
  }, []);

  return (
    <View style={tw("flex flex-1 py-1")}>
      <View
        style={tw("flex flex-row flex-wrap items-center justify-between px-2")}
      >
        <View style={tw("flex-row items-center")}>
          <Input
            style={{
              padding: 5,
              height: 30,
              width: 250,
            }}
            errorStyle={{ paddingBottom: 0 }}
            onChangeText={(val) => {
              dispatch(setPageActions({ type: "searchQuery", value: val }));
              debouncedSearch();
            }}
            value={searchQuery}
            placeholder={"Search by any column"}
            rightIcon={
              searchQuery.length > 0 ? (
                <Text
                  style={{
                    paddingRight: 5,
                    fontWeight: "bold",
                  }}
                  onPress={() => {
                    dispatch(
                      setPageActions({ type: "searchQuery", value: "" })
                    );
                    debouncedSearch();
                  }}
                >
                  X
                </Text>
              ) : undefined
            }
          />
        </View>
        <View style={tw("flex flex-row")}>
          {isLoading && (
            <ActivityIndicator
              style={{ marginRight: 5 }}
              color={colors.activityIndicator}
              testID={"loader"}
            />
          )}
          <TabBarNew
            groups={orderGroupsConfig}
            selectedGroups={orderGroups}
            onSelectionChange={(category, value) => {
              dispatch(
                setPageActions({
                  type: "orderGroups",
                  value: { category, value },
                })
              );
              refreshGrid();
            }}
          />
        </View>
        <View style={tw("flex flex-row items-center")}>
          <View style={tw("flex-row items-center mr-4")}>
            <TouchableOpacity
              style={[tw("flex-row items-center")]}
              onPress={() => {
                PageStorage.setShowNewOrdersPage(
                  "set_show_new_orders_page",
                  false
                );
                window.location.reload();
              }}
            >
              <Text style={{ color: "#294B5D" }}>{"Classic View"}</Text>
            </TouchableOpacity>
            <View style={{ marginRight: 10 }} />
            <View
              style={[
                tw("flex-row items-center"),
                {
                  backgroundColor: "#F5F5F5",
                  padding: 5,
                  borderRadius: 5,
                  shadowColor: "#000000",
                  shadowOffset: {
                    width: 0,
                    height: 1,
                  },
                  shadowRadius: 5,
                  shadowOpacity: 1.0,
                },
              ]}
            >
              <View
                style={{
                  backgroundColor: "#D2547C",
                  padding: 3,
                  borderRadius: 5,
                }}
              >
                <Text style={{ ...fonts.heading5, color: "white" }}>
                  {"Beta"}
                </Text>
              </View>
              <View style={{ marginRight: 5 }} />
              <Text style={{ ...fonts.heading5, color: "#294B5D" }}>
                {"View"}
              </Text>
            </View>
          </View>
          {isEnhancedDesignerEnabled && (
            <View
              style={[
                tw("mr-2"),
                showDesigners
                  ? {
                      backgroundColor: "#F5F5F5",
                      borderRadius: 5,
                      shadowColor: "#000000",
                      shadowOffset: {
                        width: 0,
                        height: 1,
                      },
                      shadowRadius: 5,
                      shadowOpacity: 1.0,
                    }
                  : {},
              ]}
            >
              <Tooltip
                text={Localise(messages, "Design Center")}
                renderForWebOnly={true}
              >
                <TouchableOpacity
                  onPress={() => {
                    dispatch(
                      setPageActions({
                        type: "showDesigners",
                        value: !showDesigners,
                      })
                    );
                  }}
                  testID={"open_designers_area"}
                  accessibilityLabel={"open_designers_area"}
                >
                  <Image
                    style={{
                      width: 35,
                      height: 35,
                    }}
                    resizeMode={"cover"}
                    source={IMAGES["unassigned"]}
                  />
                </TouchableOpacity>
              </Tooltip>
            </View>
          )}
          {gridState.length > 0 && (
            <TouchableOpacity
              style={tw("mr-2")}
              onPress={() => {
                setResetModalVisibile(true);
              }}
            >
              <Image
                style={{ width: 25, height: 25 }}
                resizeMode={"contain"}
                source={IMAGES["reset"]}
              />
            </TouchableOpacity>
          )}
          <TouchableOpacity
            onPress={() => {
              gridAdditionalProps?.toggleColumnPanel();
            }}
          >
            <Image
              style={{ width: 25, height: 25 }}
              resizeMode={"contain"}
              source={IMAGES["pivot_menu"]}
            />
          </TouchableOpacity>
        </View>
      </View>
      {showDesigners && (
        <View style={tw("flex flex-row items-center")}>
          <Form
            innerRef={formRef}
            initialValues={{
              designerAction: "assign-to-designer",
              assignForDesign: "",
              selectedOrders: [],
            }}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={(values) => {
              dispatch(
                assignDesigner({
                  selectedOrders: values.selectedOrders,
                  memberCode: sendingMember,
                  designer: {
                    id: designers.find((e) => e.id === values.assignForDesign)
                      .id,
                    name: designers.find((e) => e.id === values.assignForDesign)
                      .fullName,
                  },
                })
              );
            }}
            render={({
              values: {
                designerAction = "",
                assignForDesign = "",
                selectedOrders = [],
              } = {},
              handleSubmit,
            }) => {
              const enableApply =
                designerAction !== ""
                  ? designerAction === "assign-to-designer"
                    ? !!assignForDesign && selectedOrders.length
                    : selectedOrders.length
                  : false;

              return (
                <View
                  style={tw("flex flex-1 flex-row items-start mx-2")}
                  testID={"assign_for_design"}
                >
                  <View>
                    <FormFieldPicker
                      placeholder={{
                        label: "Select",
                        value: "select",
                      }}
                      containerStyle={{
                        width: "100%",
                        paddingTop: 7,
                      }}
                      data={[
                        {
                          label: `Assign to Designer`,
                          value: `assign-to-designer`,
                        },
                        {
                          label: `Remove Designer`,
                          value: `remove-designer`,
                        },
                      ]}
                      name={"designerAction"}
                    />
                    <Text style={[fonts.error, tw("text-justify mx-2")]}>
                      {Localise(
                        messages,
                        "Note:- This action applies only to the orders visible below."
                      )}
                    </Text>
                  </View>
                  {designerAction === "assign-to-designer" && (
                    <FormFieldPicker
                      placeholder={{
                        label: "Select",
                        value: "select",
                      }}
                      containerStyle={{
                        width: "20%",
                        paddingLeft: 5,
                        paddingTop: 7,
                      }}
                      data={designers.map((e) => ({
                        label: e.fullName,
                        value: e.id,
                      }))}
                      name={"assignForDesign"}
                    />
                  )}
                  <Button
                    containerStyle={{ margin: 7 }}
                    title={Localise(messages, "Apply")}
                    onPress={handleSubmit}
                    testID={"Apply"}
                    accessibilityLabel={"Apply"}
                    disabled={!enableApply}
                    loading={isLoading}
                  />
                  {showLimitMessage && (
                    <Text style={[fonts.error, tw("text-justify mx-2 my-4")]}>
                      {Localise(
                        messages,
                        "** Some records are not showing up. Please adjust your filters to display all available results."
                      )}
                    </Text>
                  )}
                </View>
              );
            }}
          />
        </View>
      )}
      <View style={tw("my-1")} />
      <AGGridContainer
        onGridReady={(instance, props) => {
          gridRef.current = instance;
          gridAdditionalProps = props;
        }}
        paginationPageSize={pageLimit}
        columnDefs={columns}
        onColumnMoved={saveGridState}
        onColumnResized={saveGridState}
        onColumnVisible={saveGridState}
        suppressAggFuncInHeader
        groupDisplayType={"singleColumn"}
        rowGroupPanelShow={"never"}
        suppressServerSideInfiniteScroll={false}
        autoGroupColumnDef={{
          headerName: "Group By - Designer",
          minWidth: 300,
          checkboxSelection: true,
          headerCheckboxSelection: true,
          suppressMenu: true,
        }}
        gridOptions={{
          suppressMenuHide: true,
          skipHeaderOnAutoSize: true,
          suppressHorizontalScroll: true,
          colResizeDefault: "shift",
          rowModelType: "serverSide",
          rowSelection: "multiple",
          groupSelectsChildren: true,
          onRowSelected: ({
            source = "",
            node: { selected = false } = {},
            data: { group = false, id = "", childOrders = [] } = {},
          } = {}) => {
            if (source === "uiSelectAll") {
              const updatedOrders = selected ? ["all"] : [];
              formRef.current.setFieldValue("selectedOrders", updatedOrders);
            } else if (source === "checkboxSelected" && group) {
              const selectedOrders = formRef.current.values.selectedOrders;
              formRef.current.setFieldValue(
                "selectedOrders",
                selected
                  ? [...selectedOrders, ...childOrders]
                  : selectedOrders.filter((e) => !childOrders.includes(e))
              );
            } else {
              const selectedOrders = formRef.current.values.selectedOrders;
              const updatedOrders = selectedOrders.includes(id)
                ? selectedOrders.filter((e) => e !== id)
                : [...selectedOrders, id];
              formRef.current.setFieldValue("selectedOrders", updatedOrders);
            }
          },
        }}
        getChildCount={getChildCount}
        onFilterChanged={refreshGrid}
        onRowClicked={(event) => {
          const { data: { group = false } = {} } = event;
          const columnId =
            event?.event?.target?.closest(".ag-cell")?.getAttribute("col-id") ??
            "";
          ![
            columnDefination.fulfillment.colId,
            columnDefination.actions.colId,
          ].includes(columnId) &&
            !group &&
            setOrderDetailsData(event.data);
        }}
        getMainMenuItems={(params) => {
          return columnDefination[params.column.colId].filterParams.sortOptions;
        }}
      />
      {orderDetailsData && (
        <div
          className="overlay-wrapper"
          onClick={() => {
            setOrderDetailsData(null);
          }}
        >
          <div className="overlay-content" onClick={(e) => e.stopPropagation()}>
            {orderDetailsData && (
              <OrderDetails
                isSmallScreen={!isDesktop}
                recordId={orderDetailsData.correlation_id}
                deliveryMethod={orderDetailsData.fulfillment}
                sourceMemberCode={sendingMember}
                actionsList={ListOfActions}
                subActionsList={ListOfSubActions}
                onComplete={() => {
                  setOrderDetailsData(null);
                }}
                onAction={() => {}}
                setMenuAction={() => {}}
                recordAction={""}
                menuAction={""}
              />
            )}
          </div>
        </div>
      )}
      <CustomModal
        modalVisible={resetModalVisible}
        modalContent={resetModalContent}
        primaryhandler={() => {
          setGridReset(true);
          setResetModalVisibile(false);
          dispatch(
            setPageActions({ type: "showDesigners", value: !showDesigners })
          );
          dispatch(resetSlice());
          refreshGrid();
        }}
        secondaryhandler={() => {
          setResetModalVisibile(false);
        }}
        contentStyle={[
          tw("border border-black p-4"),
          { backgroundColor: backgroundColors.secondary, textAlign: "center" },
        ]}
      ></CustomModal>
      <PrintIframeRenderer />
    </View>
  );
};

export default OrdersNew;
