import React from "react";
import { View } from "react-native";
import { Text } from "react-native-elements";

import tw from "tailwind-rn";
import { backgroundColors, fonts } from "styles/theme";
import { CustomModal } from "components/elements";
import { Platform } from "react-native";
import I18NContext from "library/contexts/i18N";

export const RefundVerificationModal = ({
  showModal,
  onContinue,
  isSmallScreen,
  isCancel,
  isWiredOrder,
  hasFulfillmentError,
}) => {
  const { messages, Localise } = React.useContext(I18NContext);

  const Content = () => {
    return (
      <View
        style={{
          maxWidth: isSmallScreen ? "100%" : 600,
          maxHeight: isSmallScreen ? "100%" : 400,
          margin: "auto",
          backgroundColor: backgroundColors.secondary,
          borderColor: "rgba(0, 0, 0, 0.1)",
        }}
      >
        <View
          style={[
            tw("p-3 flex flex-row items-center justify-between"),
            { backgroundColor: backgroundColors.primary },
          ]}
        >
          <Text style={{ ...fonts.heading1, ...tw("text-white") }}>
            {isCancel
              ? Localise(messages, "Cancel Verification")
              : Localise(messages, "Refund Verification")}
          </Text>
        </View>
        <View style={isSmallScreen ? {} : tw("mx-20")}>
          <Text style={[tw("p-5"), { fontSize: 14 }]}>
            {isCancel
              ? Localise(messages, "Do you want to cancel this Delivery?")
              : Localise(
                  messages,
                  "Do you want to initiate a refund for this order?"
                )}
          </Text>
          {!isCancel && isWiredOrder && !hasFulfillmentError && (
            <Text style={[tw("p-5"), { fontSize: 14 }]}>
              {Localise(messages, "Note")}
              {": "}
              {Localise(messages, "Filler has not approved order cancelation")}
            </Text>
          )}
        </View>
      </View>
    );
  };

  const modalContent = {
    content: <Content />,
    buttons: [
      {
        type: hasFulfillmentError ? "secondary" : "primary",
        title: hasFulfillmentError
          ? Localise(messages, "No")
          : Localise(messages, "Yes"),
      },
      {
        type: hasFulfillmentError ? "primary" : "secondary",
        title: hasFulfillmentError
          ? Localise(messages, "Yes")
          : Localise(messages, "No"),
      },
    ],
  };

  return (
    <>
      <CustomModal
        modalVisible={showModal}
        modalContent={modalContent}
        primaryhandler={() => onContinue("Yes")}
        secondaryhandler={() => onContinue("No")}
        contentStyle={{ backgroundColor: "white" }}
        modalStyle={
          Platform.OS !== "web"
            ? {
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                backgroundColor: "#00000070",
                color: backgroundColors.secondary,
              }
            : { padding: 0 }
        }
        isReduceBtnSpacing={true}
      ></CustomModal>
    </>
  );
};

export default RefundVerificationModal;
