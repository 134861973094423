import { createSlice } from "@reduxjs/toolkit";
import { SLICE_NAME as sliceName } from "./constants";
import { isCustomDateRangeToggle } from "library/utils/formatter";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";

export const initialState = {
  originalData: [],
  data: [],
  recordData: {},
  recordId: "",
  recordAction: "",
  deliveryMethod: "",
  sourceMemberCode: "",
  minDeliveryDate: "",
  maxDeliveryDate: "",
  addressVerificationStatus: "",
  timeStamp: null,
  loading: true,
  hasMoreOrders: false,
  isOrderModifying: false,
  isOrderLocked: { isLocked: false, lockedBy: "" },
  orderDetailTabs: [
    { key: "order-summary", title: "Order Details" },
    { key: "delivery", title: "Delivery" },
    { key: "order-journey", title: "Order Journey" },
    { key: "payment", title: "Payment" },
  ],
  activeTab: "order-summary",
  actions: {
    appliedFilters: [],
    filters: [],
    selectedShops: [],
    shops: [],
    search: {},
    sort: {},
    orderIds: "", //Comma separated Orders
  },
  loadingOnDSrequested: false,
  dsEligibilityFailureMessage: {},
  ordersToHighlight: [],
  autoPrintOrdersInfo: {},
  isDesignersAreaEnabled: false,
  dconStatus: "delivery-confirmation",
  onDemandInvoiceFields: {
    showOnDemandInvoiceForm: false,
    validateOnDemandInvoiceFields: false,
    customerEmailFields: [],
  },
};

// Redux toolkit uses Immer internally for state immutability
const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setActions: (state, action) => {
      const currentFilters = action.payload.appliedFilters;
      const prevFilters = state.actions.appliedFilters;

      const changeInDateRange = currentFilters
        ? isCustomDateRangeToggle(prevFilters, currentFilters)
        : false;

      if (changeInDateRange) {
        state.timeStamp = null;
        state.data = [];
        state.originalData = [];
      }

      state.actions = { ...state.actions, ...action.payload };
    },
    setData: (state, action) => {
      const {
        data,
        originalData,
        timeStamp,
        hasMoreOrders = false,
        isDeltaOrders,
        minDeliveryDate,
        maxDeliveryDate,
        showLoader = true,
      } = action.payload;

      state.data = cloneDeep(data);
      state.loading = showLoader ? false : true;

      if (originalData) state.originalData = cloneDeep(originalData);
      if (timeStamp) state.timeStamp = timeStamp;
      if (!isDeltaOrders) state.hasMoreOrders = hasMoreOrders;

      if (!isDeltaOrders) {
        state.minDeliveryDate = minDeliveryDate;
        state.maxDeliveryDate = maxDeliveryDate;
      } else {
        if (minDeliveryDate) {
          if (
            !state.minDeliveryDate ||
            moment(minDeliveryDate).isBefore(state.minDeliveryDate)
          )
            state.minDeliveryDate = minDeliveryDate;
        }
        if (maxDeliveryDate) {
          if (
            !state.maxDeliveryDate ||
            moment(maxDeliveryDate).isAfter(state.maxDeliveryDate)
          )
            state.maxDeliveryDate = maxDeliveryDate;
        }
      }
    },
    setRecordId: (state, action) => {
      state.recordId = action.payload;
      state.activeTab = initialState.activeTab;
    },
    setDeliveryMethod: (state, action) => {
      state.deliveryMethod = action.payload;
    },
    setRecordData: (state, action) => {
      state.recordData = action.payload;
    },
    setRecordAction: (state, action) => {
      state.recordAction = action.payload;
    },
    resetBasicInfo: (state, action) => {
      const { retainFilters } = action.payload || false;
      Object.assign(state, {
        ...state,
        recordId: "",
        recordAction: "",
        actions: {
          appliedFilters: retainFilters ? state.actions.appliedFilters : [],
          filters: retainFilters ? state.actions.filters : [],
          selectedShops: [],
          shops: [],
          search: {},
          sort: {},
        },
        isOrderModifying: false,
        isDesignersAreaEnabled: false,
      });
    },
    reset: (state) => {
      Object.assign(state, initialState);
    },
    setOrderModifying: (state, action) => {
      state.isOrderModifying = action.payload;
    },
    setOrderLocked: (state, action) => {
      state.isOrderLocked = action.payload;
    },
    setAddressVerificationStatus: (state, action) => {
      state.addressVerificationStatus = action.payload;
    },
    setLoadingOnDSrequested: (state, action) => {
      state.loadingOnDSrequested = action.payload;
    },
    setOrdersToHighlight: (state, action) => {
      state.ordersToHighlight = action.payload;
    },
    setAutoPrintOrders: (state, action) => {
      const {
        orders = [],
        timeStamp = "",
        initialTimeStamp = "",
      } = action.payload;

      state.autoPrintOrdersInfo = {
        orders: cloneDeep(orders),
        timeStamp: timeStamp || state.autoPrintOrdersInfo.timeStamp,
        initialTimeStamp:
          initialTimeStamp || state.autoPrintOrdersInfo.initialTimeStamp,
      };
    },
    setSourceMemberCode: (state, action) => {
      state.sourceMemberCode = action.payload;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setOrderDetailTabs: (state, action) => {
      state.orderDetailTabs = action.payload;
    },
    setDSEligibilityFailureMessage: (state, action) => {
      state.dsEligibilityFailureMessage = {
        ...action.payload,
      };
    },
    setDesignersAreaEnabled: (state, action) => {
      state.isDesignersAreaEnabled = action.payload;
    },
    setDconStatus: (state, action) => {
      state.dconStatus = action.payload;
    },
    setOnDemandInvoiceFields: (state, action) => {
      state.onDemandInvoiceFields = action.payload;
    },

    // noop - only actions
    fetchData: () => {},
    fetchAutoPrintOrders: () => {},
    fetchAutoPrintOrdersLastSyncTime: () => {},
    updateAutoPrintOrders: () => {},
    sendEmailInvoices: () => {},
    fetchAutoEmailInvoiceOrders: () => {},
    sendAutoEmailInvoiceOrders: () => {},
  },
});

export const {
  setLoading,
  setActions,
  setData,
  setRecordId,
  setRecordData,
  setDeliveryMethod,
  setRecordAction,
  resetBasicInfo,
  reset,
  fetchData,
  setOrderModifying,
  setOrderLocked,
  setAddressVerificationStatus,
  setEnableReviewPayment,
  setLoadingOnDSrequested,
  setDSEligibilityFailureMessage,
  setOrdersToHighlight,
  fetchAutoPrintOrders,
  fetchAutoPrintOrdersLastSyncTime,
  updateAutoPrintOrders,
  setAutoPrintOrders,
  setSourceMemberCode,
  setActiveTab,
  setOrderDetailTabs,
  setDesignersAreaEnabled,
  setDconStatus,
  setOnDemandInvoiceFields,
  sendEmailInvoices,
  fetchAutoEmailInvoiceOrders,
  sendAutoEmailInvoiceOrders,
} = slice.actions;

export default { [sliceName]: slice.reducer };
