import { put, takeLatest, call, select } from "redux-saga/effects";

import moment from "moment";
import { request } from "library/utils/request";

import { processSelectedProductResponse } from "library/utils/productDetails";

import {
  setPageDesigners,
  setAPIResponse,
  setPageData,
  fetchDesigners,
  fetchOrders,
  filterOrders,
  fetchPrintDetails,
  triggerMeetBallAction,
  assignDesigner,
} from "./slice";
import { selectActions, selectAPIResponse, selectPageLimit } from "./selector";

function* handleFetchDesigners(action = {}) {
  const designersRequest = (members) =>
    request("get-designer-suggestions", {
      members,
    });
  const { memberCodes, callback = () => {} } = action?.payload ?? {};

  try {
    const designers = yield call(designersRequest, memberCodes);
    yield put(setPageDesigners(designers));
    callback();
  } catch (error) {
    callback();
    console.log(`Error fetching designers - ${error}`);
  }
}

function* handleFetchOrders(action = {}) {
  const ordersRequest = (params) => request("get-orders-new", params);

  try {
    const {
      sendingMember,
      offset,
      callback = () => {},
    } = action?.payload ?? {};

    const apiResponse = yield select(selectAPIResponse);
    const limit = yield select(selectPageLimit);
    const { searchQuery, orderGroups, showDesigners } = yield select(
      selectActions
    );

    let filterQuery = "";

    if (orderGroups.length) {
      const selectedCategories = orderGroups.map((e) => e.category);

      // Group - Date Filters
      if (selectedCategories.includes("date")) {
        const dateFilters = orderGroups.find(
          (e) => e.category === "date"
        ).values;

        const today = moment().add(0, "days").format("YYYY-MM-DD");
        const tomorrow = moment().add(1, "days").format("YYYY-MM-DD");
        const future = moment().add(2, "days").format("YYYY-MM-DD");

        if (dateFilters.length === 1) {
          if (dateFilters.includes(0)) {
            filterQuery += `&delivery_date=${today}`;
          } else if (dateFilters.includes(1)) {
            filterQuery += `&delivery_date=${tomorrow}`;
          } else if (dateFilters.includes(2)) {
            filterQuery += `&delivery_date.gte=${future}`;
          }
        } else if (dateFilters.length === 2) {
          if (dateFilters.includes(0) && dateFilters.includes(1)) {
            filterQuery += `&delivery_date.in=${today},${tomorrow}`;
          } else if (dateFilters.includes(0) && dateFilters.includes(2)) {
            filterQuery += `&delivery_date.gte=${today}&delivery_date.ne=${tomorrow}`;
          } else if (dateFilters.includes(1) && dateFilters.includes(2)) {
            filterQuery += `&delivery_date.gte=${tomorrow}`;
          }
        } else {
          filterQuery += `&delivery_date.gte=${today}`;
        }
      }

      // Group - Action Filters
      if (selectedCategories.includes("actions")) {
        filterQuery += `&action_type_needed.ne=`;
      }

      // Group - Delivery Status Filters
      if (selectedCategories.includes("delivery_status")) {
        const deliveryStatusFilters = orderGroups.find(
          (e) => e.category === "delivery_status"
        ).values;

        filterQuery += `&delivery_status.in=${deliveryStatusFilters.join(",")}`;
      }

      // Group - Order Status Filters
      if (selectedCategories.includes("order_status")) {
        const OrderStatusFilters = orderGroups.find(
          (e) => e.category === "order_status"
        ).values;

        filterQuery += `&order_status.in=${OrderStatusFilters.join(",")}`;
      }
    }

    // if (gridState.length) {
    //   const selectedFilters = gridState
    //     .map((e) => (!isEmpty(e.appliedFilter) ? e.appliedFilter : false))
    //     .filter(Boolean);

    //   // Group - Filters
    //   selectedFilters.forEach((e) => {
    //     const filterKeys = Object.keys(e);
    //     filterKeys.map((k) => {
    //       const filterValues = e[k];
    //       filterQuery += `&${k}.in=${filterValues.join(",")}`;
    //     });
    //   });

    //   // Group - Sorting
    //   let sortQuery = "";
    //   const selectedSorts = gridState
    //     .map((e) => (!isEmpty(e.appliedSort) ? e.appliedSort : false))
    //     .filter(Boolean);

    //   if (selectedSorts.length > 0) {
    //     sortQuery += "&sort=";
    //     selectedSorts.forEach((e) => {
    //       const query = e.split("::");
    //       sortQuery += `${query[1]}:${query[2]},`;
    //     });
    //     filterQuery += sortQuery.slice(0, -1);
    //   }
    // }

    const { api: response, totalRows } = yield call(ordersRequest, {
      sendingMember,
      searchQuery,
      limit: showDesigners ? 1000 : limit,
      offset,
      filterQuery,
    });

    yield put(setAPIResponse([...apiResponse, ...response]));
    yield put(filterOrders({ callback, totalRows }));
  } catch (error) {
    console.log(`Error fetching orders - ${error}`);
  }
}

function* handleFilterOrders(action = {}) {
  try {
    const { callback = () => {}, totalRows = 0 } = action?.payload ?? {};

    const api = yield select(selectAPIResponse);
    const limit = yield select(selectPageLimit);

    const data = processOrdersResponse(api);

    yield put(setPageData(data));

    // AG-Grid requires only latest data
    callback &&
      callback({
        data: data.slice(data.length - limit, data.length),
        totalRows,
      });
  } catch (error) {
    console.log(`Error filtering orders - ${error}`);
  }
}

function* handleFetchPrintDetails(action = {}) {
  const orderDetailRequest = (params) => request("order-details", params);
  const productDetailRequest = (params) =>
    request("get-product-details", params);

  const {
    recordId = "",
    deliveryMethod = "",
    sourceMemberCode = "",
    callback = () => {},
  } = action?.payload ?? {};

  try {
    const res = yield call(orderDetailRequest, {
      recordId,
      deliveryMethod,
      sourceMemberCode,
    });

    if (res && res.orderItems) {
      const {
        orderSource,
        multiProductEligible = false,
        lineItems,
        deliveryInfo: { deliveryMethod },
      } = res.orderItems[0];

      const products = multiProductEligible
        ? lineItems.filter((lineItem) => lineItem.type === "Product")
        : lineItems;

      if (products.length) {
        let promises = [];

        for (let i = 0; i < products.length; i++) {
          const productId = products[i]?.productFirstChoiceCode || "";
          if (productId) {
            const productResponse = yield call(productDetailRequest, {
              productId: productId.toUpperCase(),
              siteId: sourceMemberCode,
              orderSource:
                orderSource === "FLORIST" &&
                deliveryMethod !== "FLORIST_PARTNER"
                  ? "LOCAL"
                  : orderSource,
            });
            promises.push(productResponse);
          }
        }

        Promise.all(promises).then((responses = []) => {
          let productsInfo = [];

          responses.map((productResp) => {
            if (productResp?.products?.length > 0) {
              const productInfo = processSelectedProductResponse(
                (productResp && productResp.products) || []
              );
              productsInfo.push(productInfo);
            }
          });

          callback({ res, productsInfo });
        });
      }
    }
  } catch (error) {
    console.log(`Error filtering orders - ${error}`);
  }
}

function* handleMeetBallAction(action = {}) {
  const meetBallActionRequest = (requestMethod, params) =>
    request(requestMethod, params);
  const { requestMethod, params, callback = () => {} } = action?.payload ?? {};

  try {
    yield call(meetBallActionRequest, requestMethod, params);
    callback();
  } catch (error) {
    callback(error);
    console.log(`Error triggering meetball action for the order - ${error}`);
  }
}

function* handleSetAssignDesigner(action = {}) {
  const assignDesignerRequest = (params) => request("assign-designer", params);
  const {
    designer,
    selectedOrders,
    memberCode,
    callback = () => {},
  } = action?.payload ?? {};

  try {
    const isAllOrders = selectedOrders.includes("all");
    const apiResponse = yield select(selectAPIResponse);
    const orders = isAllOrders
      ? apiResponse.map((e) => ({
          orderId: e.correlation_id,
          orderItemId: e.external_id,
          deliveryMethod: e.fulfillment,
        }))
      : selectedOrders.map((e) => ({
          orderId: apiResponse.find((i) => i.id === e).correlation_id,
          orderItemId: apiResponse.find((i) => i.id === e).external_id,
          deliveryMethod: apiResponse.find((i) => i.id === e).fulfillment,
        }));

    yield call(assignDesignerRequest, {
      designer,
      orders,
      memberCode,
    });
    callback();
  } catch (error) {
    console.log(`Error while assigning the designer - ${error}`);
  }
}

const processOrdersResponse = (api = []) => {
  const response = api.map((e, i) => ({
    ...e,
    designer: e.designer ? e.designer : { id: 0, name: "Unassigned" },
    recipient: {
      id: e.recipient_id,
      name: "Rachael",
      phone: "555-555-5555",
      address: "123 Main St, Lisle, IL 60540",
      status: "Verified",
    },
    customer: {
      id: e.customer_id,
      name: "Chris",
      phone: "555-555-5555",
    },
    price: { amount: "$122", status: "Approved" },
    status: {
      ...e.status,
      needsAction: [
        "fulfillment_error",
        "payment_error",
        "price_request_approved",
        "date_request_approved",
        "price_change_request",
        "date_change_request",
        "approve_request_denied",
        "chat_message",
      ],
    },
  })); /* response - This is a static data & will be removed when API is holding a valid response */

  return response;
};

/**
 * Watcher subscribes to FETCH_REQUEST actions
 */
export function* watchSaga() {
  yield takeLatest(fetchDesigners.type, handleFetchDesigners);
  yield takeLatest(fetchOrders.type, handleFetchOrders);
  yield takeLatest(filterOrders.type, handleFilterOrders);
  yield takeLatest(fetchPrintDetails.type, handleFetchPrintDetails);
  yield takeLatest(triggerMeetBallAction.type, handleMeetBallAction);
  yield takeLatest(assignDesigner.type, handleSetAssignDesigner);
}

export default watchSaga;
