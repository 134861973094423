import { createSlice } from "@reduxjs/toolkit";
import { SLICE_NAME as sliceName } from "./constants";
import { Platform } from "react-native";

let currentPage = "dashboard";

if (Platform.OS === "web") {
  const pathArray = window.location.pathname.split("/") || [];
  currentPage = pathArray[1] || "dashboard";
}

export const initialState = {
  loading: false,
  addressVerificationInfo: {},
  addons: [],
  selectedproducts: [],
  currentproduct: {},
  isAutoFill: false,
  YOffSetScroll: 0,
  currentPage,
  searchFieldText: {},
  shopCode: "all",
  createSubscriptionShopCode: "",
  shopGroup: "",
  members: [],
  currentMember: {},
  imagesData: {},
  isRouteEditing: false,
  isAdhocStopEditing: false,
  isRouteSave: false,
  isRouteSaveSuccess: false,
  isShowSignatureModal: false,
  localAddons: [],
  currentAddon: {},
  molAddons: [],
  isBulkDSRequestUploadInProgress: false,
  showSessionInfo: false,
  designersInfo: [],
  distanceInMiles: "",
  upsertDesignerStatus: "",
  isRouteOptimised: true,
  optimiseRoute: false,
  isOptimiseDone: false,
  isQuickBooksSessionExpired: false,
  qrScanVariant: "",
  openScanner: false,
  logoURLs: {},
  deleteCard: { showDeleteCardModal: false, isLoading: false },
  isRecalcTaxAndFees: false,
  isUserAction: "",
  isOrdersCallInProgress: false,
  isOrderSearchApplied: false,
  isOrderSplitCallInProgress: false,
};

// Redux toolkit uses Immer internally for state immutability
const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    fetchAddons: (state) => {
      state.loading = true;
    },
    fetchlocalAddons: (state) => {
      state.loading = true;
    },
    setAddonsData: (state, action) => {
      state.loading = false;
      state.addons = action.payload;
    },
    setLocalAddonsData: (state, action) => {
      state.loading = false;
      state.localAddons = [...state.localAddons, ...action.payload];
    },
    setCurrentAddon: (state, action) => {
      state.loading = false;
      state.currentAddon = action.payload || {};
    },
    fetchMOLAddons: (state) => {
      state.loading = true;
    },
    setMOLAddonsData: (state, action) => {
      state.loading = false;
      state.molAddons = [...state.molAddons, action.payload];
    },
    fetchImageData: (state) => {
      state.loading = true;
    },
    setImageData: (state, action) => {
      const { name: key, data } = action.payload;
      state.loading = false;
      state.imagesData = { ...state.imagesData, [key]: data };
    },
    fetchProduct: (state) => {
      state.loading = true;
    },
    setSelectedProduct: (state, action) => {
      state.loading = false;
      state.selectedproducts = [...state.selectedproducts, action.payload];
    },
    setCurrentProduct: (state, action) => {
      state.loading = false;
      state.currentproduct = action.payload;
    },
    resetCurrentProduct: (state) => {
      state.loading = false;
      state.currentproduct = {};
    },
    setIsAutoFill: (state, action) => {
      state.isAutoFill = action.payload;
    },
    setYOffSetScroll: (state, action) => {
      state.YOffSetScroll = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    updateAvsInfo: (state, action) => {
      const { index, ...avsInfo } = action.payload;
      state.addressVerificationInfo[index] = avsInfo;
      state.loading = false;
    },
    setSearchFieldText: (state, action) => {
      state.searchFieldText = { ...state.searchFieldText, ...action.payload };
    },
    setShopCode: (state, action) => {
      state.shopCode = action.payload;
    },
    setShopGroup: (state, action) => {
      state.shopGroup = action.payload;
    },
    fetchMember: (state) => {
      state.loading = true;
    },
    setMember: (state, action) => {
      state.loading = false;
      state.members = {
        ...state.members,
        ...action.payload,
      };
    },
    setIsRouteEditing: (state, action) => {
      state.isRouteEditing = action.payload;
    },
    setIsAdhocStopEditing: (state, action) => {
      state.isAdhocStopEditing = action.payload;
    },
    setIsRouteSave: (state, action) => {
      state.isRouteSave = action.payload;
    },
    setIsRouteSaveSuccess: (state, action) => {
      state.isRouteSaveSuccess = action.payload;
    },
    setIsShowSignatureModal: (state, action) => {
      state.isShowSignatureModal = action.payload;
    },
    setSignatureImage: (state, action) => {
      state.signatureImage = action.payload;
    },
    setIsBulkDSRequestUploadInProgress: (state, action) => {
      state.isBulkDSRequestUploadInProgress = action.payload;
    },
    setCreateSubscriptionShopCode: (state, action) => {
      state.createSubscriptionShopCode = action.payload;
    },
    setShowSessionInfo: (state, action) => {
      state.showSessionInfo = action.payload;
    },
    setDesignersData: (state, action) => {
      state.designersInfo = action.payload;
    },
    setDistanceInMiles: (state, action) => {
      state.distanceInMiles = action.payload;
    },
    setIsRouteOptimised: (state, action) => {
      state.isRouteOptimised = action.payload;
    },
    setOptimiseRoute: (state, action) => {
      state.optimiseRoute = action.payload;
    },
    setIsRouteOptimiseDone: (state, action) => {
      state.isOptimiseDone = action.payload;
    },
    setIsQuickBooksSessionExpired: (state, action) => {
      state.isQuickBooksSessionExpired = action.payload;
    },
    setQrScanVariant: (state, action) => {
      state.qrScanVariant = action.payload;
    },
    setOpenScanner: (state, action) => {
      state.openScanner = action.payload;
    },
    setLogoURLs: (state, action) => {
      if (!state.logoURLs[action.payload.shop])
        state.logoURLs = {
          ...state.logoURLs,
          [action.payload.shop]: action.payload.url,
        };
    },
    setDeleteCardModal: (state, action) => {
      state.deleteCard = action.payload;
    },
    setRecalcTaxAndFees: (state, action) => {
      state.isRecalcTaxAndFees = action.payload;
    },
    setUserAction: (state, action) => {
      state.isUserAction = action.payload;
    },
    setUpsertDesignerStatus: (state, action) => {
      state.upsertDesignerStatus = action.payload;
    },
    setIsOrdersCallInProgress: (state, action) => {
      state.isOrdersCallInProgress = action.payload;
    },
    setIsOrderSearchApplied: (state, action) => {
      state.isOrderSearchApplied = action.payload;
    },
    setIsOrderSplitCallInProgress: (state, action) => {
      state.isOrderSplitCallInProgress = action.payload;
    },

    // noop - only actions
    fetchData: () => {},
    fetchFtdAdminData: () => {},
    fetchDesignerSuggestions: () => {},
    fetchDistanceInMiles: () => {},
    setDesigner: () => {},
    fetchQuickBooksSessionExpired: () => {},
  },
});

export const {
  setLoading,
  fetchAddons,
  fetchlocalAddons,
  setAddonsData,
  setLocalAddonsData,
  setCurrentAddon,
  fetchMOLAddons,
  setMOLAddonsData,
  fetchImageData,
  setImageData,
  fetchProduct,
  setSelectedProduct,
  setCurrentProduct,
  resetCurrentProduct,
  setIsAutoFill,
  setYOffSetScroll,
  setCurrentPage,
  setIsOrdersCallInProgress,
  setIsOrderSearchApplied,
  setIsOrderSplitCallInProgress,
  updateAvsInfo,
  setSearchFieldText,
  setShopCode,
  setShopGroup,
  fetchMember,
  setMember,
  setIsRouteEditing,
  setIsAdhocStopEditing,
  setIsRouteSave,
  setIsRouteSaveSuccess,
  setIsShowSignatureModal,
  setSignatureImage,
  setIsBulkDSRequestUploadInProgress,
  setCreateSubscriptionShopCode,
  setShowSessionInfo,
  setDesignersData,
  setDistanceInMiles,
  fetchDesignerSuggestions,
  fetchDistanceInMiles,
  setDesigner,
  setIsRouteOptimised,
  setOptimiseRoute,
  setIsRouteOptimiseDone,
  setIsQuickBooksSessionExpired,
  fetchQuickBooksSessionExpired,
  setQrScanVariant,
  setOpenScanner,
  setLogoURLs,
  setDeleteCardModal,
  setRecalcTaxAndFees,
  setUserAction,
  setUpsertDesignerStatus,
} = slice.actions;

export default { [sliceName]: slice.reducer };
