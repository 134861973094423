import React, { useContext, useEffect } from "react";
import { View, Linking, Platform } from "react-native";
import { Text } from "react-native-elements";
import { useDispatch } from "react-redux";

import tw from "tailwind-rn";
import moment from "moment";

import { fonts } from "styles/theme";
import I18NContext from "library/contexts/i18N";

import {
  Form,
  FormField,
  FormFieldPicker,
  SubmitButton,
} from "components/elements/forms";
import UserProfileContext from "library/contexts/userProfile";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { DeviceContext } from "library/contexts/appSettings";

import {
  rejectReasons,
  cancelReasons,
  cancelDenyReasons,
  fulfillmentRejectReasons,
  fulfillmentErrorReasons,
} from "../helper";
import RefundVerificationModal from "../refund-verification-modal";
import {
  setCancelFormOnRefund,
  setRefundFormOnCancel,
} from "library/sagas/ongoing/order-details/slice";
import { getReasonFormValidationSchema } from "../yup";

const ReasonForm = ({
  orderDetails,
  selectedAction,
  triggerRequest,
  onActionSubmit,
  setSelectedAction,
  isSmallScreen,
  refundDetails,
  orderAmounts,
  showPayment,
  paymentItemStatus,
}) => {
  const { messages, Localise } = React.useContext(I18NContext);
  const dispatch = useDispatch();

  let refundAmount = 0;
  for (let i = 0; i < refundDetails?.length; i++) {
    refundAmount += refundDetails[i]?.amount;
  }
  refundAmount = parseFloat(refundAmount).toFixed(2);
  const totalAmount = parseFloat(
    orderAmounts?.find((obj) => obj.name === "amountChargedToCustomer")
      ?.value || 0
  ).toFixed(2);

  const {
    deliveryInfo: { deliveryDate, deliveryMethod },
    createdOn,
    hasPendingPayLaterPayment = false,
    status,
    hasFulfillmentError = false,
  } = orderDetails;

  const isWiredOrder = deliveryMethod === "FLORIST_PARTNER" ? true : false;

  const defaultValues = {
    reason: "",
    notes: "",
  };

  const validationSchema = getReasonFormValidationSchema(Localise, messages);

  const { userProfile: { firstName: operator } = {} } =
    useContext(UserProfileContext);

  const [onChangeValidation, setOnChangeValidation] = useStateIfMounted(false);
  const [refundValidation, setRefundValidation] = useStateIfMounted(false);
  const [cancelOrderReq, setCancelOrderReq] = useStateIfMounted({});

  const formSubmitTrigger = (values) => {
    const orderReq = {
      operator,
      comments: values?.notes,
      reason: values?.reason,
      ...(selectedAction !== "cancel-deny" && { deliveryDate }),
    };
    setCancelOrderReq(orderReq);
    dispatch(setCancelFormOnRefund(""));

    if (selectedAction === "cancel") {
      setRefundValidation(true);

      if (
        !showPayment ||
        Number(totalAmount) === 0 ||
        totalAmount === refundAmount ||
        hasPendingPayLaterPayment
      ) {
        triggerRequest({ requestType: selectedAction, orderReq: orderReq });
      }

      /**
       * When user submitted cancel form, we are setting selected action as empty in order to prevent showing cancel form in sidecar
       * before getting response from service
       * */
      // setSelectedAction("");
    } else {
      onActionSubmit(orderReq);
    }
  };

  const headTitle = {
    reject: "Reject",
    cancel: "Cancel",
    cancelDeny: "Cancel Deny",
    refund: "Refund",
  };

  const isShowDRPCharges = () => {
    if (selectedAction === "reject") {
      const orderReceivedDate = moment(createdOn);
      const deadlineDate = moment(createdOn).toDate();
      const currentDate = moment().toDate();
      let isDRPChargeApplicable = false;
      const openHour = 9;
      let closeHour = 17;
      let cutoffTime = 15; // 2 Hour's before the shop closing time.
      let addHours = 18; // Hours b/w the shop closed & Opened.

      // If the order rejecting day is on Saturday or Sunday
      if (orderReceivedDate.day() === 6 || orderReceivedDate.day() === 0) {
        closeHour = 13;
        cutoffTime = 11; // 2 Hour's before the shop closing time.
        addHours = 22;
      }

      if (
        orderReceivedDate.hour() >= openHour &&
        orderReceivedDate.hour() < closeHour
      ) {
        if (orderReceivedDate.hour() < cutoffTime) {
          deadlineDate.setHours(orderReceivedDate.hour() + 2);
          if (currentDate >= deadlineDate) {
            isDRPChargeApplicable = true;
          }
        } else {
          // Next day
          deadlineDate.setHours(orderReceivedDate.hour() + addHours);
          if (currentDate >= deadlineDate) {
            isDRPChargeApplicable = true;
          }
        }
      } else {
        // Next Day
        if (orderReceivedDate.hour() >= closeHour) {
          deadlineDate.setDate(orderReceivedDate.date() + 1);
        }
        // Setting the time to 2 hours later after the shop opened
        deadlineDate.setHours(11);
        deadlineDate.setMinutes(0);
        deadlineDate.setSeconds(0);
        if (currentDate >= deadlineDate) {
          isDRPChargeApplicable = true;
        }
      }

      if (isDRPChargeApplicable) {
        return true;
      }
    }
    return false;
  };

  const { isMobile } = React.useContext(DeviceContext);

  return (
    <View>
      <View style={tw("mb-3")}>
        <Text style={fonts.heading3}>
          {Localise(messages, headTitle[selectedAction])}
        </Text>
        {/* {selectedAction === "cancel" && (
          <Text style={{ ...fonts.default, paddingTop: 5 }}>
            {Localise(
              messages,
              "Note, cancelling this order will also refund this order."
            )}
          </Text>
        )} */}
      </View>

      <Form
        initialValues={defaultValues}
        validationSchema={validationSchema}
        validateOnChange={onChangeValidation}
        validateOnBlur={onChangeValidation}
        onSubmit={formSubmitTrigger}
        render={({ submitCount }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            if (submitCount > 0) {
              setOnChangeValidation(true);
            }
          }, [submitCount]);
          return (
            <>
              <View style={tw("m-1")}>
                <View style={tw("flex flex-row my-1")}>
                  <Text
                    style={{
                      ...fonts.heading5,
                      width: 125,
                    }}
                  >
                    {Localise(messages, "Select Reason")}
                  </Text>
                  <View style={{ width: 195 }}>
                    <FormFieldPicker
                      placeholder={{ label: "Select Reason", value: "" }}
                      containerStyle={{ width: "100%" }}
                      data={
                        selectedAction === "cancel"
                          ? hasFulfillmentError && status === "REJECTED"
                            ? fulfillmentRejectReasons
                            : hasFulfillmentError && status === "ERROR"
                            ? fulfillmentErrorReasons
                            : cancelReasons
                          : selectedAction === "reject"
                          ? rejectReasons
                          : cancelDenyReasons
                      }
                      name="reason"
                      label=""
                      defaultValue={defaultValues.reason}
                    />
                  </View>
                </View>

                <View style={tw("flex flex-row my-1")}>
                  <Text style={{ ...fonts.heading5, width: 125 }}>
                    {Localise(messages, "Notes")}
                  </Text>
                  <View style={{ width: 195 }}>
                    <FormField
                      autoCapitalize="none"
                      autoCorrect={false}
                      name="notes"
                      placeholder="Add additional notes if needed"
                      multiline={true}
                      numberOfLines={2}
                    />
                  </View>
                </View>

                <View
                  style={{
                    flexDirection: isMobile ? "column-reverse" : "row",
                    justifyContent: isMobile ? "space-between" : "flex-end",
                    alignItems: "center",
                  }}
                  testID="reason-form-action-row"
                >
                  {isShowDRPCharges() && (
                    <Text
                      style={{
                        textAlign: isMobile ? "center" : "auto",
                        flex: 1,
                      }}
                    >
                      {Localise(
                        messages,
                        "Please note, the Delayed Response Policy requires a filling florist to reject an order within two hours of receiving it. By rejecting this order, you might incur a DRP charge. Read more about the Delayed Response Policy at"
                      )}{" "}
                      <Text
                        style={{ ...fonts.link1, color: "#0077cc" }}
                        onPress={() =>
                          Platform.OS === "web"
                            ? window.open("http://ftdi.com/quality/drp")
                            : Linking.openURL("http://ftdi.com/quality/drp")
                        }
                      >
                        FTDi.com/quality/drp
                      </Text>
                      .
                    </Text>
                  )}
                  <View style={tw("mx-5")}>
                    <Text
                      style={{ ...fonts.heading4, marginVertical: 8 }}
                      onPress={() => {
                        setSelectedAction("");
                        dispatch(setCancelFormOnRefund(""));
                      }}
                    >
                      {Localise(messages, "Nevermind")}
                    </Text>
                  </View>

                  <SubmitButton
                    containerStyle={{ margin: 5 }}
                    title={"Confirm"}
                    error={""}
                  />
                </View>
              </View>
            </>
          );
        }}
      />
      {showPayment &&
        totalAmount !== refundAmount &&
        paymentItemStatus !== "REFUNDED" &&
        !hasPendingPayLaterPayment && (
          <RefundVerificationModal
            showModal={refundValidation}
            isSmallScreen={isSmallScreen}
            onClose={() => {
              setRefundValidation(false);
              triggerRequest({
                requestType: "cancel",
                orderReq: cancelOrderReq,
              });
            }}
            onContinue={(val) => {
              if (val === "Yes") {
                dispatch(setRefundFormOnCancel(val));
              }
              setRefundValidation(false);
              triggerRequest({
                requestType: "cancel",
                orderReq: cancelOrderReq,
              });
            }}
            isWiredOrder={isWiredOrder}
            hasFulfillmentError={hasFulfillmentError}
          />
        )}
    </View>
  );
};

export default ReasonForm;
